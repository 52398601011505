import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "content", "excerpt"]

  expand() {
    this.buttonTarget.classList.add("hidden")
    this.excerptTarget.classList.add("hidden")
    this.contentTarget.classList.remove("hidden")
  }
}

import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "helpers"

export default class extends Controller {
  static targets = ["error"]
  static values = { url: String }

  enter(event) {
    if (event.keyCode === 13) {
      this.submit(event)
    }
  }

  submit(event) {
    event.preventDefault()

    const data = new FormData(this.element)
    const options = {
      method: "POST",
      body: data,
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      }
    }

    fetch(this.urlValue, options)
      .then(response => {
        return response.ok ? response.text() : Promise.reject()
      })
      .then(html => {
        this.element.outerHTML = html
      })
      .catch(error => {
        this.showError()
      })
  }

  showError() {
    this.errorTarget.classList.remove("hidden")
  }
}

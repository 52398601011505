import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["summary", "details"]
  static classes = ["active"]
  static values = {
    expanded: String
  }

  connect() {
    this.summaryTargets.forEach(summary => {
      summary.setAttribute("aria-expanded", summary.id === this.expandedValue)
    })

    this.detailsTargets.forEach(details => {
      details.toggleAttribute("hidden", details.getAttribute("aria-labelledby") !== this.expandedValue)
    })
  }

  toggle(event) {
    const target = event.currentTarget
    const domId = target.getAttribute("aria-controls")
    const expanded = target.getAttribute("aria-expanded")

    this.summaryTargets.forEach(summary => {
      summary.setAttribute("aria-expanded", expanded === "false" && summary.id === target.id)
    })

    this.detailsTargets.forEach(details => {
      details.toggleAttribute("hidden", expanded === "true" || details.id !== domId)
    })
  }
}

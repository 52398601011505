document.addEventListener("turbo:frame-missing", (event) => {
  message = `The response did not contain the expected Turbo frame element <turbo-frame id="${event.target.id}">.`

  if (window.Rollbar) {
    Rollbar.error(message, {
      request_url: event.detail.response.url,
      request_uuid: event.detail.response.headers.get("x-request-id"),
      cloudflare_ray_id: event.detail.response.headers.get("cf-ray")
    })
  } else {
    console.error(message)
  }
})

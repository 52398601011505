<template>
  <div>
    <div v-if="renewalTerms()" class="flex flex-row items-center justify-center">
      <p class="opacity-80 leading-snug text-base font-normal relative text-center @2xl:text-left renewal-terms">
        {{ renewalTerms() }}

        <span v-show="showTooltip" id="tooltip" class="absolute mt-1 ml-1" data-tippy-content="tooltip" ref="tooltip">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="w-4 h-4 stroke-current opacity-80" role="img"><g stroke-linejoin="round" stroke-linecap="round" stroke-width="2" fill="none"><path stroke="none" d="M0 0h24v24H0z"></path><circle cx="12" cy="12" r="9"></circle><path d="M12 8h.01"></path><path d="M11 12h1v4h1"></path></g></svg>
        </span>
      </p>
    </div>

    <p v-if="renewalCount" class="opacity-80 leading-snug @2xl:text-left text-center">
      {{ t(".payments_total", { count: renewalCount }) }}
    </p>
  </div>
</template>

<script>
import tippy from "tippy.js"
import { formatCurrency } from "helpers/currency"
import { mapGetters, mapState } from "vuex"

const ONE_USE = 1
const TWO_USES = 2

export default {
  props: {
    intervalCount: Number,
    intervalUnit: String,
    renewalCount: Number,
    trial: Object
  },

  mounted() {
    tippy("[data-tippy-content]")
    this.$refs.tooltip._tippy.setContent(this.t(".renewal_price_disclaimer"))
  },

  computed: {
    limitedDiscountPaymentsDescription() {
      if (!this.limitedDiscount) return

      return this.t(`.payments`, { 
        count: this.remainingDiscountUses, 
        smart_count: this.remainingDiscountUses
      })
    },

    limitedDiscountPeriodDescription() {
      if (!this.limitedDiscount) return

      if (this.remainingDiscountUses === 1) {
        return this.t(`.period.${this.intervalUnit}`)
      } else {
        return this.t(`.periods.${this.intervalUnit}`, {
          count: this.remainingDiscountUses, 
          smart_count: this.remainingDiscountUses
        })
      }
    },

    remainingDiscountUses() {
      if ((this.hasCustomRenewalPrice || this.hasCustomRenewalFrequency) && this.paidTrial) {
        return this.baseCouponDiscount.limit - TWO_USES
      } else if (this.hasCustomRenewalPrice || this.trial) {
        return this.baseCouponDiscount.limit - ONE_USE
      } else {
        return this.baseCouponDiscount.limit
      }
    },

    period() {
      return this.t(`.periods.${this.intervalUnit}`, {
        count: this.intervalCount,
        smart_count: this.intervalCount
      })
    },

    showAfterTrialPrice() {
      if (!this.trial) return false
      if (this.recurringDiscount && this.renewalPriceTotal === 0) return false

      const validTrialConditions = (this.freeTrial || this.intervalCount > 1) || (this.paidTrial && this.hasCustomRenewalPrice)
      const validDiscounts = this.oneTimeDiscount || this.limitedDiscount

      return validTrialConditions && (validDiscounts || this.hasCustomRenewalPrice)
    },

    showRenewalPrice() {
      return this.hasCustomRenewalPrice || this.trial || this.oneTimeDiscount
    },

    showTooltip() {
      return this.displayTaxAmount && (this.showRenewalPrice || this.limitedDiscount)
    },

    ...mapGetters("checkout", [
      "displayTaxAmount",
      "freeTrial",
      "hasCustomRenewalPrice",
      "hasCustomRenewalFrequency",
      "limitedDiscount",
      "oneTimeDiscount",
      "paidTrial",
      "recurringDiscount",
      "renewalPriceTotal",
      "renewalPriceAfterTrialTotal",
      "renewalPriceWithLimitedDiscountTotal"
    ]),

    ...mapState("checkout", [
      "baseCouponDiscount",
      "tax"
    ])
  },

  methods: {
    renewalTerms() {
      if (!this.intervalUnit) return

      let key = ".renewal_terms"

      if (this.showRenewalPrice) {
        key += "_with_price"
      }

      if (this.limitedDiscount && this.remainingDiscountUses > 0) {
        key += "_with_limited_discount"
      }

      return this.constructRenewalTerms(key)
    },

    constructRenewalTerms(key) {
      let terms = ""

      if (this.showAfterTrialPrice) {
        terms += this.t(".price_after_trial", {
          price: formatCurrency(this.renewalPriceAfterTrialTotal, { showCode: false })
        })
      }

      terms += this.t(key, {
        limited_discount_period_description: this.limitedDiscountPeriodDescription,
        limited_discount_payments_description: this.limitedDiscountPaymentsDescription,
        period: this.period,
        price_with_limited_discount: formatCurrency(this.renewalPriceWithLimitedDiscountTotal, { showCode: false }),
        renewal_price: formatCurrency(this.renewalPriceTotal, { showCode: false }),
        smart_count: this.intervalCount
      })

      return terms
    }
  }
}
</script>

import Polyglot from "node-polyglot"

const nestedLookup = function(obj, path) {
  path = path.split(".")

  return path.reduce((prev, curr) => prev && prev[curr], obj)
}

class I18n extends Polyglot {
  // Polyglot has a weird pluralization syntax. Instead of separate
  // keys representing the different plural forms (one/other/many/etc),
  // it requires everything to live in the same key, separated by a delimiter.
  // Example:
  //
  // I18n (Ruby)                          POLYGLOT.JS
  // month:                               month: Month |||| %{count} months |||| %{count} months
  //   one:   Month
  //   many:  %{count} months
  //   other: %{count} months
  //
  // Not only is this syntax terrible to work with, Lokalise doesn't support it.
  // This method allows us to keep using the conventional (one/other) syntax
  // in the back-end while conforming to Polyglot's front-end requirements.
  //
  // We can't rely on their phrases object (`this.phrases`) since they
  // flatten it on initialization, making it very hard to fetch an object
  // with all the plural translations. Instead, we use the raw translation
  // data (`memberful.config.i18nData`) to perform the lookup.
  t(key, options = {}) {
    if (options.smart_count) {
      const pluralForms = ["zero", "one", "two", "few", "many", "other"]
      const pluralPhrases = nestedLookup(memberful.config.i18nData, key)
      const orderedPhrases = pluralForms.map(el => pluralPhrases[el]).filter(el => el !== undefined)

      const phrase = Object.values(orderedPhrases).join(" |||| ")

      return Polyglot.transformPhrase(phrase, options, this.currentLocale)
    }

    return super.t(key, options)
  }
}

export default new I18n({
  phrases: memberful.config.i18nData,
  locale: memberful.config.locale
})

<template>
  <div class="mb-3">
    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <input
      ref="input"
      required
      autocomplete="email"
      class="input"
      type="email"
      :aria-label="placeholder"
      :pattern="pattern"
      :placeholder="placeholder"
      :value="value"
      @change="validate"
      @input="$emit('input', $event.target.value)"
      @invalid.prevent="invalid"
    />

    <button
      v-if="suggestion"
      class="block cursor-pointer text-red-500 pl-1"
      type="button"
      @click="accept(suggestion.email)">
      {{ suggestion.message }}
    </button>
  </div>
</template>

<script>
import { getSearchParam } from "helpers"
import { EMAIL_REGEX } from "helpers/email"

export default {
  props: {
    name: String,
    placeholder: String,
    unique: {
      type: Boolean,
      default: true
    },
    value: String
  },

  data: function() {
    return {
      error: null,
      pattern: EMAIL_REGEX.source,
      suggestion: null
    }
  },

  watch: {
    error: function(value) {
      this.$refs.input.setCustomValidity(value ? value : "")
    }
  },

  mounted() {
    const email = getSearchParam(this.name)

    if (email && EMAIL_REGEX.test(email)) {
      this.accept(email)
    }
  },

  methods: {
    accept(email) {
      this.$emit("input", email)
      this.$nextTick(() => this.validate())
    },

    invalid() {
      if (!this.error) {
        this.$emit("invalid", this.t(".error"))
      }
    },

    validate() {
      this.error = null

      if (EMAIL_REGEX.test(this.value)) {
        this.suggestion = null

        let params = {
          email: this.value,
          validate_uniqueness: this.unique
        }

        this.$http.get("/email_validation", { searchParams: params }).json().then(json => {
          this.error = json.error
          this.suggestion = json.suggestion
        })
      }
    }
  }
}
</script>

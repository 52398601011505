import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    autosubmit(this.element)
  }
}

export function autosubmit(element) {
  let interval = setInterval(() => {
    if (document.readyState === "complete") {
      clearInterval(interval)
      element.requestSubmit()
    }
  }, 50)
}

import Vue from "vue"
import Vuex from "vuex"

import checkout from "./modules/checkout"

Vue.use(Vuex)

const modules = {
  checkout
}

export function createStore(options) {
  if (!options) return

  const store = new Vuex.Store()

  for (const [namespace, state] of Object.entries(options)) {
    store.registerModule(namespace, modules[namespace])
    store.commit(`${namespace}/setInitialState`, state)
  }

  return store
}

<template>
  <div class="mb-4">
    <fieldset v-if="inputType === 'checkbox'">
      <legend class="label">{{ label }} <span v-if="required" class="input-required">{{ t(".required") }}</span></legend>

      <label v-for="option in options" class="input-checkbox">
        <input
          type="checkbox"
          :required="required && !answer.length"
          :value="option.id"
          v-model="answer"
          @invalid.prevent
        >
        <span class="ml-2">{{ option.value }}</span>
      </label>
    </fieldset>

    <template v-else>
      <label :for="domId">{{ label }} <span v-if="required" class="input-required">{{ t(".required") }}</span></label>

      <input
        v-if="inputType === 'text'"
        class="input"
        maxlength="250"
        type="text"
        :id="domId"
        :required="required"
        v-model="answer"
        @invalid.prevent
      />

      <textarea
        v-if="inputType === 'textarea'"
        class="input"
        maxlength="1000"
        :id="domId"
        :required="required"
        v-model="answer"
        @invalid.prevent>
      </textarea>

      <select
        v-if="inputType === 'select'"
        class="select"
        :id="domId"
        :required="required"
        v-model="answer"
        @invalid.prevent
      >
        <option disabled value="">{{ t(".select_one") }}</option>

        <option v-for="option in options" :value="option.id">
          {{ option.value }}
        </option>
      </select>

      <div v-if="inputType === 'password'">
        <input
          autocomplete="new-password"
          class="input"
          type="password"
          :id="domId"
          :required="required"
          v-model="answer"
          minlength="6"
          maxlength="72"
          @invalid.prevent
        />

        <p class="text-gray-600 text-sm mt-1">{{ t(".password_description") }} {{ t(".password_requirements") }}</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    inputType: String,
    label: String,
    options: Array,
    required: Boolean,
    value: [Array, String, Number]
  },

  data() {
    return {
      answer: this.value || (this.inputType === "checkbox" ? [] : "")
    }
  },

  computed: {
    domId() {
      return `custom_field_${this.id}`
    },

    optionIds() {
      return this.options.map(option => option.id)
    },
  },

  mounted() {
    this.filterAnswers()
  },

  watch: {
    answer(answer) {
      this.$emit("input", answer)
    }
  },

  methods: {
    filterAnswers() {
      if (this.inputType == "checkbox") {
        this.answer = this.answer.filter(answer => this.optionIds.includes(answer))
      } else if (this.inputType == "select") {
        if (!this.optionIds.includes(this.answer)) {
          this.answer = ""
        }
      }
    }
  }
}
</script>

import { Controller } from "@hotwired/stimulus";

// https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/examples/disclosure-navigation-hybrid/

export default class extends Controller {
  disconnect() {
    this.close()
  }

  enter(event) {
    const control = event.currentTarget.querySelector("[data-hover=true]")

    if (control) {
      control.setAttribute("aria-expanded", true)
    }
  }

  close(event) {
    if (event?.type === "click" && this.element.contains(event.target)) return

    const activeControl = this.element.querySelector("[aria-expanded=true]")

    if (activeControl) {
      activeControl.setAttribute("aria-expanded", false)
      activeControl.setAttribute("data-hover", true)

      if (event?.type === "keydown") activeControl.focus()
    }
  }

  leave(event) {
    const control = event.currentTarget.querySelector("[data-hover=true]")

    if (control) {
      control.setAttribute("aria-expanded", false)
    }
  }

  toggle(event) {
    const control = event.currentTarget

    control.setAttribute("aria-expanded", control.getAttribute("data-hover") === "true")
    control.setAttribute("data-hover", control.getAttribute("data-hover") === "false")
  }
}

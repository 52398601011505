<template>
  <div>
    <div v-if="!container" ref="spinner" class="spinner-wrapper loading">
      <div class="spinner">
        <svg class="spinner__circle" viewBox="25 25 50 50">
          <circle class="spinner__path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
        </svg>
      </div>
    </div>

    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    ready: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    loading: function(isLoading) {
      isLoading ? this.showSpinner() : this.hideSpinner()
    },

    ready: function() {
      this.spinner.classList.remove("loading")
    }
  },

  computed: {
    spinner: function() {
      return this.container || this.$refs.spinner
    }
  },

  created() {
    this.container = document.getElementsByClassName("spinner-wrapper")[0]
  },

  methods: {
    showSpinner() {
      this.timeout = setTimeout(() => {
        this.spinner.classList.add("processing")
      }, 50)
    },

    hideSpinner() {
      clearTimeout(this.timeout)
      this.spinner.classList.remove("processing")
    }
  }
}
</script>

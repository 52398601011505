import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["highlight"]

  connect() {
    this.element.scrollIntoView({ behavior: "auto", block: "center" })
    if (this.hasHighlightClass)
      this.element.classList.add(this.highlightClass)
  }
}

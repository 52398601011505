import { Controller } from "@hotwired/stimulus"
import { notificationsEnabled } from "./notifications_controller"
import I18n from "i18n"

export default class extends Controller {
  static targets = ["newCount", "notice"]
  static values = {
    interval: Number,
    url: String,
    logoUrl: String,
  }

  connect() {
    this.pollCommentsSince = Date.now()
    this.interval = setInterval(this.pollForUpdates.bind(this), this.intervalValue)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  pollForUpdates() {
    if (this.pollingNotRequired) return false

    fetch(this.urlWithParams)
      .then(response => response.json())
      .then(data => {
        const { new_comments, likes } = data

        if (new_comments > 0) {
          this.showCommentsNotice(new_comments)
        }

        if (notificationsEnabled() && (likes.length > 0)) {
          likes.forEach(like => {
            this.buildNotification(like)
          })
        }
      })
  }

  refreshComments() {
    this.pollCommentsSince = Date.now()
  }

  get pollingNotRequired() {
    return !(notificationsEnabled() || this.hasNoticeTarget)
  }

  get urlWithParams() {
    let url = new URL(this.urlValue)

    url.searchParams.append("comments_since", this.pollCommentsSince)
    if (notificationsEnabled())
      url.searchParams.append("likes_since", Date.now() - this.intervalValue)

    return url
  }

  showCommentsNotice(new_comments) {
    this.newCountTarget.innerHTML = I18n.t("posts.comments.new_comments", {smart_count: new_comments})
    this.noticeTarget.classList.remove("hidden")
  }

  buildNotification(like) {
    new Notification(document.title, {
      body: like.summary,
      image: this.logoUrlValue,
      icon: this.logoUrlValue
    })
  }
}

<template>
  <div class="flex @2xl:flex-row @2xl:flex-wrap flex-col items-center @2xl:items-end justify-center pb-1 @2xl:pb-0 @2xl:pt-2">
    <h2 class="font-bold @2xl:w-full @2xl:block hidden">{{ name }}</h2>
    <div class="@2xl:w-full @2xl:py-2 @2xl:flex @2xl:flex-wrap" :class="[(!!renewalCount) ? '@2xl:items-center' : '@2xl:items-baseline', (baseCouponDiscount && baseCouponDiscount.couponType !== 'recurring') ? '@2xl:flex-col' : '']">
      <h3 class="@2xl:text-5xl text-3xl font-bold tracking-tight block @2xl:inline-block @2xl:mr-2 text-center @2xl:text-left" :class="{'@2xl:text-4xl text-3xl': trial }">
        {{ price }}
      </h3>
      <RenewalTerms v-bind="item" />
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "helpers/currency"
import { mapGetters, mapState } from "vuex"
import RenewalTerms from "./RenewalTerms"

export default {
  components: {
    RenewalTerms
  },

  props: {
    name: String,
    renewalCount: Number,
    trial: Object
  },

  computed: {
    price() {
      if (this.trial) {
        if (this.trial.paid) {
          return this.t(".paid_trial", {
            amount: formatCurrency(this.total, { showCode: false }),
            length: this.trial.length,
            smart_count: this.trial.length }
          )
        } else {
          return this.t(".free_trial", { length: this.trial.length, smart_count: this.trial.length })
        }
      } else {
        return formatCurrency(this.total, { showCode: false })
      }
    },

    ...mapGetters("checkout", [
      "total"
    ]),

    ...mapState("checkout", [
      "baseCouponDiscount",
      "item"
    ])
  }
}
</script>

<template>
  <div ref="input"></div>
</template>

<script>
const focusIfUnfocused = function(element) {
  const focusedElement = document.activeElement

  if (focusedElement === null || focusedElement.tagName === "BODY") {
    element.focus()
  }
}

export default {
  beforeMount() {
    const elements = this.$stripe.elements()

    this.stripeElement = elements.create("card", { style: window.cardElementStyles })
    this.stripeElement.on("change", event => this.$emit("change", event))
    this.stripeElement.on("ready", this.ready)
  },

  mounted() {
    this.stripeElement.mount(this.$refs.input)
  },

  beforeDestroy() {
    this.stripeElement.destroy()
  },

  methods: {
    focus() {
      this.stripeElement.focus()
    },

    ready() {
      focusIfUnfocused(this.stripeElement)
    }
  }
}
</script>

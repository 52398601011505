import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";

export default class extends Controller {
  static values = { 
    content: String,
    templateId: String,
    appendToParent: Boolean,
    hideArrow: Boolean,
    placement: String
  }

  connect() {
    const settings = {
      allowHTML: true,
      content: this.contentValue,
      arrow: this.hideArrowValue ? false : true,
      placement: this.placementValue || 'top',
      appendTo: this.appendToParentValue ? "parent" : document.body,
    }

    if (this.templateIdValue) {
      settings['arrow'] = false
      settings['appendTo'] = document.body,
      settings['content'] = document.getElementById(this.templateIdValue).innerHTML
      settings['interactive'] =  true
      settings['theme'] = 'html'
    }

    tippy(this.element, settings)
  }
}

<template>
  <div class="tax-preview mb-3" v-show="!emptyTaxPreview">
    <div class="tax-preview__summary">
      <span v-if="displayTaxRate">
        {{ taxRate }} {{ taxName }}
      </span>

      <span v-if="displayReverseCharge">
        {{ t(".reverse_charge") }}
      </span>
    </div>
  
    <div v-if="displayBreakdown" class="tax-preview__breakdown mb-3 opacity-85">
      <div class="flex items-center justify-between pb-1">
        <span>{{ itemName }}</span>
        <span>{{ formatCurrency(discountSubtotal) }}</span>
      </div>
      <div class="flex items-center justify-between pb-1">
        <span>{{ taxName }}</span>
        <span>{{ formatCurrency(tax.amount) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "helpers/currency"
import { mapState, mapGetters } from "vuex"

export default {
  props: {
    itemName: String,
    showTax: Boolean
  },

  mounted() {
    this.$store.dispatch("checkout/fetchTax")
  },

  computed: {
    displayReverseCharge() {
      return this.tax && this.tax.reverseCharge
    },

    displayBreakdown() {
      return this.showTax && this.displayTaxAmount
    },

    emptyTaxPreview() {
      return !(this.displayTaxRate || this.displayReverseCharge || this.displayBreakdown)
    },

    taxName() {
      return this.t(`taxes.names.${this.tax.name}`)
    },

    ...mapGetters("checkout", [
      "discountSubtotal",
      "displayTaxAmount",
      "displayTaxRate",
      "taxRate"
    ]),

    ...mapState("checkout", [
      "tax"
    ])
  },

  methods: {
    formatCurrency,
  }
}
</script>

<template>
  <form
    novalidate
    @submit.prevent="submit"
    class="checkout-form h-full"
  >
    <MemberLayout>
      <template v-slot:header>
        <div class="@2xl:flex flex-col pb-1 @2xl:pb-0">
          <CheckoutItem v-bind="item" class="pb-3" />
          <div class="checkout-form-divider"></div>
          <div class="@2xl:block flex items-center justify-between flex-wrap relative">
            <h2 class="@2xl:hidden text-base leading-tight font-semibold antialiased my-px">{{ item.name }}</h2>

            <div v-if="retentionDiscount" class="mt-2 mb-1 w-full flex items-center justify-between flex-wrap relative">
              {{ t(".loyalty_discount") }} <span class="opacity-80">-{{ formatCurrency(retentionDiscount.amount) }}</span>
            </div>

            <div v-if="referralDiscount" class="mt-2 mb-1 w-full flex items-center justify-between flex-wrap relative">
              {{ t(".referral_discount") }} <span class="opacity-80">-{{ formatCurrency(referralDiscount.amount) }}</span>
            </div>

            <CouponInput name="coupon" />
          </div>
        </div>
      </template>

      <div
        v-if="pastDue"
        v-html="t('.past_due_html', { plan: item.name })"
        class="mb-4 member-helper-text"></div>

      <div
        v-else-if="expired"
        v-html="t('.expired_html', { plan: item.name, expiration_date: expired })"
        class="mb-4 member-helper-text"></div>

      <PaymentForm
        ref="paymentForm"
        v-bind="$attrs"
        :itemName="item.name"
        @success="handleRedirect"
      />
      <div class="pt-1">
        <div class="text-sm text-center text-neutral-600 opacity-90">
          <p class="mt-3">{{ t("components.checkout_form.cancel_anytime") }}</p>
        </div>
      </div>
    </MemberLayout>
  </form>
</template>

<script>
import CheckoutItem from "./CheckoutItem"
import MemberLayout from "./MemberLayout"
import CouponInput from "./CouponInput"
import PaymentForm from "./PaymentForm"
import { formatCurrency } from "helpers/currency"
import { mapState, mapGetters } from "vuex"

export default {
  components: {
    CheckoutItem,
    MemberLayout,
    CouponInput,
    PaymentForm
  },

  inheritAttrs: false,

  props: {
    expired: String,
    pastDue: Boolean
  },

  computed: {
    ...mapState("checkout", [
      "item",
    ]),
    ...mapGetters("checkout", [
      "referralDiscount",
      "retentionDiscount"
    ])
  },

  methods: {
    submit() {
      this.$refs.paymentForm.submit()
    },

    formatCurrency,

    handleRedirect(response) {
      Turbo.cache.clear()
      Turbo.visit(response["redirect_to"], { action: "replace" })
    }
  }
}
</script>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['nav', 'icon'];

  connect() {
    this.hideNavHandler = this.hideNav.bind(this)
    document.addEventListener("turbo:before-cache", this.hideNavHandler)
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.hideNavHandler)
  }

  toggle(event) {
    if (event) {
      event.preventDefault()
    }

    this.navTargets.forEach((target) => {
      target.classList.toggle('mobile-nav-active')
    })
  }

  hideNav() {
    if (document.querySelector('.mobile-nav-active') !== null) {
      this.toggle()
    }
  }
}

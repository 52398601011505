import { getMetaValue } from "helpers"
import http from "helpers/http"
import Vue from "vue"
import { i18nMixin } from "helpers/vue_i18n"

Vue.mixin(i18nMixin)

Vue.prototype.$http = http

const stripeKey = getMetaValue("stripe-key")
const stripeAccount = getMetaValue("stripe-account")

if (stripeKey) {
  const stripe = window.Stripe(stripeKey, {
    locale: memberful.config.locale,
    stripeAccount: stripeAccount
  })

  if (process.env.RAILS_ENV === "test") {
    stripe.paymentRequest = require("test/mock_payment_request.js").bind(stripe, stripe.paymentRequest)
  }

  Vue.prototype.$stripe = stripe
}

const numberFormatter = new Intl.NumberFormat(memberful.config.locale, {
  minimumFractionDigits: memberful.config.currency.decimal_places,
  maximumFractionDigits: memberful.config.currency.decimal_places
})

export function formatCurrency(amountCents, { showCode = "$" } = {}) {
  let { code, decimal_places, format, symbol } = memberful.config.currency
  let amount = amountCents / 10 ** decimal_places

  let result = format.replace("%u", symbol).replace("%n", numberFormatter.format(amount))

  if (symbol === showCode) {
    result += ` ${code.toUpperCase()}`
  }

  return result
}

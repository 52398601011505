import { getMetaValue } from "helpers"
import ky from "ky"

export default ky.extend({
  hooks: {
    beforeRequest: [
      request => {
        request.headers.set("X-CSRF-Token", getMetaValue("csrf-token"))
      }
    ]
  },
  retry: 0
})

class AlignElement extends HTMLElement {
}

class AlignLeftElement extends AlignElement {
}

class AlignCenterElement extends AlignElement {
}

class AlignRightElement extends AlignElement {
}

export const registerCustomAlignElements = () => {
  window.customElements.define("align-left", AlignLeftElement)
  window.customElements.define("align-center", AlignCenterElement)
  window.customElements.define("align-right", AlignRightElement)
}

export const configureTrixAlignmentAttributes = () => {
  document.addEventListener("trix-before-initialize", (event) => {
    if (!event.target.dataset.controller?.includes("trix-align-text")) return

    const alignConfig = {
      nestable: false,
      exclusive: true,
    }

    Trix.config.blockAttributes.alignLeft = { ...alignConfig, tagName: "align-left" }
    Trix.config.blockAttributes.alignCenter = { ...alignConfig, tagName: "align-center" }
    Trix.config.blockAttributes.alignRight = { ...alignConfig, tagName: "align-right" }

    Trix.config.blockAttributes.heading1.terminal = false
  })
}

export const configureTrixUrlPlaceholder = () => {
  document.addEventListener("trix-before-initialize", () => {
    Trix.config.lang.urlPlaceholder = "Enter a URL starting with either https:// or http://"
  })
}

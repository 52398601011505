<template>
  <div v-if="taxIdType" class="flex items-center justify-between mb-3">
    <input
      class="address-input__tax-number flex-1 mr-4 input"
      type="text"
      ref="taxIdInput"
      :class="validClass"
      :aria-label="taxIdLabel"
      :placeholder="t('.placeholder', { label: taxIdLabel })"
      :value="value"
      @change="update($event.target.value)">

    <button type="button" class="validate-tax-number btn btn-gray" @click="validate" :aria-label="t('.update')">{{ t(".update") }}</button>
  </div>
</template>

<script>
import taxConfig from "taxes.json"
import { mapState } from "vuex"

const COUNTRY_PREFIX = /^[a-z]{2}/i

export default {
  props: {
    country: String
  },

  data: function() {
    return {
      valid: null
    }
  },

  computed: {
    taxIdType() {
      if (taxConfig[this.country]) return taxConfig[this.country].tax_id_type
    },

    taxIdLabel() {
      return this.t(`taxes.tax_id_types.${this.taxIdType}`)
    },

    validClass() {
      if (this.value && this.valid !== null) return this.valid ? "valid" : "invalid"
    },

    ...mapState("checkout", {
      value: "taxId"
    })
  },

  watch: {
    country: function() {
      this.update()
    }
  },

  methods: {
    insertCountryPrefix(value) {
      if (value && this.taxIdType === "eu_vat" && !COUNTRY_PREFIX.test(value)) {
        value = this.country + value
      }

      return value
    },

    update(value) {
      value = this.insertCountryPrefix(value)
      this.valid = null
      this.$store.commit("checkout/setTaxId", value)
    },

    validate() {
      const params = { tax_id: { country: this.country, value: this.value } }

      this.$http.post("/tax_ids/validate", { json: params }).json().then(json => {
        this.valid = json.valid

        if (this.valid) {
          this.$store.dispatch("checkout/fetchTax")
        }
      })
    },

    focus() {
      this.$nextTick(() => {
        this.$refs.taxIdInput.focus()
      })
    }
  }
}
</script>

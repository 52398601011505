<template>
  <Spinner :loading="loading" :ready="ready">
    <div v-for="error in errors" class="error-message">
      {{ error }}
    </div>

    <PaymentRequestButton
      v-if="express"
      v-show="requireCard"
      :default="true"
      :express="true"
      :item="itemName"
      :requestPhone="requirePhoneNumber"
      :requestShipping="requireBillingAddress || requireMailingAddress"
      :showTotal="showTotal"
      @cancel="loading = false"
      @payment-method="submitPaymentMethod"
      @show="loading = true"
      @ready="ready = true"
    />

    <slot/>

    <div v-if="requireCard" class="memberful-payment-form">
      <template v-if="useSavedCard">
        <SavedCard v-bind="savedCard" @change="changeCard"/>

        <div class="my-3">
          <TaxLocation />
        </div>

        <TaxPreview
          v-if="requireBillingAddress && taxable"
          v-bind="billingAddress"
          :itemName="itemName"
          :showTax="showTax"
          class="border-t pt-3"
        />
      </template>

      <template v-else>
        <CardInput
          ref="cardInput"
          v-show="!usePaymentRequest"
          :address="billingAddress"
          @error="loading = false"
          @complete="cardPostalCode = $event.postalCode"
          @payment-method="submitPaymentMethod"
        />

        <TaxAddressInput
          v-if="requireBillingAddress"
          :cardPostalCode="cardPostalCode"
          :useCardInput="!usePaymentRequest"
          @error="showError"
        />

        <TaxPreview
          v-if="requireBillingAddress"
          :itemName="itemName"
          :showTax="showTax"
        />
      </template>
    </div>

    <PolicyNotice :sitePolicies="sitePolicies" />

    <CloudflareChallenge
      :issueCount="challengeCount"
      @complete="submitForm(challengeParams)"
      @error="loading = false"
    />

    <button v-if="!usePaymentRequest || !requireCard" class="btn-main btn-expanded" type="submit" :disabled="loading"
    data-website-preview-target="disable">
      {{ buttonText }}
    </button>

    <PaymentRequestButton
      ref="paymentRequestButton"
      v-if="!express"
      v-show="requireCard"
      :default="!defaultToSavedCard"
      :item="itemName"
      :requestShipping="requireMailingAddress"
      :showTotal="showTotal"
      @cancel="loading = false"
      @change="usePaymentRequest = $event"
      @payment-method="submitPaymentMethod"
      @ready="ready = true"
    />
  </Spinner>
</template>

<script>
import CardInput from "./CardInput"
import CloudflareChallenge from "./CloudflareChallenge"
import PaymentRequestButton from "./PaymentRequestButton"
import PolicyNotice from "./PolicyNotice"
import SavedCard from "./SavedCard"
import Spinner from "./Spinner"
import TaxAddressInput from "./TaxAddressInput"
import TaxLocation from "./TaxLocation"
import TaxPreview from "./TaxPreview"
import { formatCurrency } from "helpers/currency"
import { decamelizeKeys } from "humps"
import { mapGetters, mapState } from "vuex"

export default {
  components: {
    CardInput,
    CloudflareChallenge,
    PaymentRequestButton,
    PolicyNotice,
    SavedCard,
    Spinner,
    TaxAddressInput,
    TaxLocation,
    TaxPreview
  },

  props: {
    action: String,
    amount: Number,
    buttonLabel: String,
    express: Boolean,
    formData: Object,
    itemName: String,
    method: {
      type: String,
      default: "POST"
    },
    requireBillingAddress: Boolean,
    requireCard: {
      type: Boolean,
      default: true
    },
    requireMailingAddress: Boolean,
    requirePhoneNumber: Boolean,
    savedCard: Object,
    showTax: {
      type: Boolean,
      default: true
    },
    showTotal: {
      type: Boolean,
      default: true
    },
    sitePolicies: Array
  },

  data: function() {
    return {
      cardPostalCode: null,
      challengeCount: 0,
      challengeParams: null,
      defaultToSavedCard: this.savedCard != null,
      errors: [],
      loading: false,
      ready: false,
      usePaymentRequest: false
    }
  },

  computed: {
    buttonText() {
      if (this.showTotal && this.total && this.requireCard) {
        return this.buttonLabel + ": " + formatCurrency(this.total)
      } else {
        return this.buttonLabel
      }
    },

    hasErrorListener() {
      return this.$listeners && this.$listeners.error
    },

    useSavedCard() {
      return this.defaultToSavedCard && !this.usePaymentRequest
    },

    ...mapGetters("checkout", [
      "serialize",
      "taxable",
      "total"
    ]),

    ...mapState("checkout", [
      "billingAddress",
      "taxId"
    ])
  },

  methods: {
    changeCard(event) {
      this.defaultToSavedCard = false
      this.$nextTick(() => this.$refs.cardInput.focus())
    },

    convertPaymentRequest({ payerEmail: email, payerName: name, payerPhone: phone, shippingAddress: address }) {
      let params = {}

      if (name && email) {
        params.full_name = name
        params.email = email
      }

      if (phone && this.requirePhoneNumber) {
        params.phone_number = phone
      }

      if (address && this.requireMailingAddress) {
        params.city = address.city
        params.country = address.country
        params.postal_code = address.postalCode
        params.state = address.region
        params.street = address.addressLine.join(" ")
      }

      return params
    },

    submit() {
      this.errors = []
      this.loading = true

      if (!this.requireCard || this.useSavedCard) {
        this.submitForm()
      } else if (this.usePaymentRequest) {
        this.$refs.paymentRequestButton.submit()
      } else {
        this.$refs.cardInput.submit()
      }
    },

    submitPaymentMethod({ paymentMethod, ...event }) {
      this.paymentRequestData = this.convertPaymentRequest(event)

      this.submitForm({ payment_method_id: paymentMethod.id }).then(error => {
        if (event.complete) {
          if (error) {
            event.complete("fail")
          } else {
            event.complete("success")
          }
        }
      })
    },

    submitForm(stripeParams) {
      let params = this.serialize({
        ...this.formData,
        ...this.paymentRequestData
      })

      params.challenge_count = this.challengeCount
      params.payment_session = this.paymentSession(stripeParams)

      return this.$http(this.action, { method: this.method, json: params }).json().then(json => {
        if (json.payment_intent_client_secret) {
          this.handleCardAction(json.payment_intent_client_secret)
        } else if (json.setup_intent_client_secret) {
          this.confirmCardSetup(json.setup_intent_client_secret)
        } else {
          this.loading = false
          this.$emit("success", json)
        }
      }).catch(error => {
        this.detectCloudflareChallenge(error.response, stripeParams).then(text => {
          if (text) {
            let json = JSON.parse(text)

            for (let error of json.errors) {
              this.showError(error)
            }

            if (error.response.status == 402) {
              this.defaultToSavedCard = false
            }
          }
        }).catch(error => {
          this.showError(this.t(".payment_failed"))
        })

        return error
      })
    },

    paymentSession(stripeParams) {
      let result = {
        useSavedCard: this.useSavedCard,
        ...stripeParams
      }

      if (this.requireBillingAddress && !this.useSavedCard) {
        result.billingAddress = this.billingAddress
        result.taxId = this.taxId
      }

      return decamelizeKeys(result)
    },

    detectCloudflareChallenge(response, params) {
      return response.text().then(text => {
        if (response.status == 403 && text.indexOf("security check") !== -1) {
          this.challengeCount++
          this.challengeParams = params
        } else {
          return text
        }
      })
    },

    showError(error) {
      this.loading = false

      if (this.hasErrorListener) {
        this.$emit("error", error)
      } else if (!this.errors.includes(error)) {
        this.errors.push(error)
      }
    },

    handleCardAction(clientSecret) {
      this.$stripe.handleCardAction(clientSecret).then(result => {
        if (result.error) {
          this.showError(result.error.message)
        } else {
          this.submitForm({
            payment_intent_id: result.paymentIntent.id,
            payment_method_id: result.paymentIntent.payment_method
          })
        }
      })
    },

    confirmCardSetup(clientSecret) {
      this.$stripe.confirmCardSetup(clientSecret).then(result => {
        if (result.error) {
          this.showError(result.error.message)
        } else {
          this.submitForm({
            payment_method_id: result.setupIntent.payment_method,
            setup_intent_id: result.setupIntent.id
          })
        }
      })
    }
  }
}
</script>

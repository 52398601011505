import { Controller } from "@hotwired/stimulus"
import formDataEntries from "form-data-entries"
import { debounce } from "lodash"

export default class extends Controller {
  static classes = [ "priceInputAnimation" ]
  static targets = [ "details", "priceInput" ]
  static values = { url: String }

  connect() {
    if (this.hasPriceInputTarget) {
      this.priceInputTarget.addEventListener("animationend", () => this.priceInputTarget.classList.remove(this.priceInputAnimationClass))
    }
  }

  update = debounce( (event) => {
    this.abortPreviousFetchRequest()

    fetch(this.url, { signal: this.abortController.signal }).
      then(response => response.text()).
      then(html => {
        this.validate()
        this.detailsTarget.outerHTML = html
      })
  }, 500)

  // private

  abortPreviousFetchRequest() {
    if(this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
  }

  validate() {
    if (this.hasPriceInputTarget) {
      if(this.minimumPrice && this.price < this.minimumPrice) {
        this.price = this.minimumPrice
        this.animatePriceInput()
      } else if(this.maximumPrice && this.price > this.maximumPrice) {
        this.price = this.minimumPrice
        this.animatePriceInput()
      }
    }
  }

  animatePriceInput() {
    this.priceInputTarget.classList.add(this.priceInputAnimationClass)
    this.priceInputTarget.focus()
  }

  get minimumPrice() {
    return parseFloat(this.priceInputTarget.min)
  }

  get maximumPrice() {
    return Number(this.priceInputTarget.max)
  }

  get price() {
    return Number(this.priceInputTarget.value) || 0
  }

  set price(newValue) {
    this.priceInputTarget.value = newValue
  }

  get url() {
    let url = new URL(this.urlValue)
    formDataEntries(this.element).forEach( ([name, value]) => url.searchParams.set(name, value))
    return url
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.listener = document.addEventListener("turbo:before-frame-render", event => {
      if (this.element.contains(event.target)) this.open()
    })
  }

  disconnect() {
    document.removeEventListener("turbo:before-frame-render", this.listener)
  }

  open() {
    this.element.classList.add("member-sidemenu--visible")
  }

  close() {
    this.element.classList.remove("member-sidemenu--visible")
  }
}

import { Controller } from "@hotwired/stimulus"
import I18n from "i18n"
import tippy from "tippy.js";

export default class extends Controller {
  static targets = [ "source" ]

  copy(event) {
    event.preventDefault()
    let target = event.currentTarget
    navigator.clipboard.writeText(this.textToCopy).then(() => {
      this.showFeedback(target, I18n.t("controllers.clipboard.copied"))
    })
  }

  // private

  get textToCopy() {
    const { value, innerText } = this.sourceTarget
    return value || innerText
  }

  showFeedback(element, message) {
    tippy(element, {
      appendTo: this.element,
      content: message,
      showOnCreate: true,
      onHidden: (instance) => {
        instance.destroy()
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["emailField", "newPasswordField", "currentPassword"]

  showCurrentPassword() {
    if(!this.hasCurrentPasswordTarget) {
      return
    }

    if(this.changingCredentials()) {
      this.currentPasswordTarget.classList.remove("hidden")
    } else {
      this.currentPasswordTarget.classList.add("hidden")
    }
  }

  // private

  changingCredentials() {
    return this.emailChanged() || this.newPasswordPresent()
  }

  emailChanged() {
    return this.emailFieldTarget.value != this.emailFieldTarget.dataset.oldValue
  }

  newPasswordPresent() {
    return this.newPasswordFieldTarget.value.length > 0
  }
}

<template>
  <div>
    <label for="seats" class="opacity-85 label block">{{ t(".label") }}</label>

    <div v-if="error" class="error-message">{{ error }}</div>

    <div class="relative mb-4">
      <input
          ref="input"
          v-model.lazy.number="value"
          class="pl-3 input"
          id="seats"
          inputmode="numeric"
          pattern="\d+"
          type="number"
          @change="save"
          @invalid.prevent
          @keydown.enter.prevent
      />

      <div class="flex absolute top-0 right-0">
        <button
          class="inline-flex w-12 items-center justify-center border-t border-b focus:border-gray-500 hover:bg-gray-200 duration-300 transition text-lg flex-shrink-0 hover:shadow-sm bg-white pt-[0.75rem] pb-[0.75rem] border-gray-400 touch-none border-l"
          type="button"
          :aria-label="t('.decrease_amount')"
          :title="t('.decrease_amount')"
          @click="update(value - 1)"
        >
          <SvgIcon name="minus" class="fill-current w-4 h-auto flex-shrink-0" />
        </button>

        <button
          class="inline-flex w-12 items-center justify-center border-t border-b focus:border-gray-500 hover:bg-gray-200 duration-300 transition text-lg flex-shrink-0 hover:shadow-sm bg-white pt-[0.75rem] pb-[0.75rem] border-gray-400 touch-none border-l border-r rounded-r"
          type="button"
          :aria-label="t('.increase_amount')"
          :title="t('.increase_amount')"
          @click="update(value + 1)"
        >
          <SvgIcon name="plus" class="fill-current w-4 h-auto flex-shrink-0" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "./icons/SvgIcon"
import { debounce } from "lodash"

export default {
  components: {
    SvgIcon
  },

  props: {
    includedSeats: Number,
    maximumSeatCount: Number,
    price: Number,
    seatPrice: Number
  },

  data() {
    const lineItem = this.$store.state.checkout.lineItems.find(lineItem => {
      return lineItem.name === "additional_seats"
    })

    return {
      error: null,
      value: this.includedSeats + (lineItem ? lineItem.value : 0)
    }
  },

  computed: {
    additionalSeatsPrice() {
      return this.additionalSeatsQuantity * this.seatPrice
    },

    additionalSeatsQuantity() {
      return this.value ? (this.value - this.includedSeats) : 0
    },

    lineItem() {
      return {
        name: "additional_seats",
        price: this.additionalSeatsPrice,
        value: this.additionalSeatsQuantity
      }
    }
  },

  created() {
    this.debouncedSave = debounce(this.save, 500)
  },

  methods: {
    invalid() {
      if (this.maximumSeatCount) {
        this.error = this.t(".error.range", { minimum: this.includedSeats, maximum: this.maximumSeatCount })
      } else {
        this.error = this.t(".error.minimum", { minimum: this.includedSeats })
      }
    },

    rangeOverflow(value = this.value) {
      return this.maximumSeatCount && value > this.maximumSeatCount
    },

    rangeUnderflow(value = this.value) {
      return value < this.includedSeats
    },

    save() {
      if (this.validate()) {
        this.$emit("change", this.value)
        this.$store.dispatch("checkout/updateLineItem", this.lineItem)
      }
    },

    update(value) {
      if (this.rangeOverflow(value)) {
        this.value = this.maximumSeatCount
      } else if (this.rangeUnderflow(value)) {
        this.value = this.includedSeats
      } else {
        this.value = value
      }

      this.debouncedSave()
    },

    validate() {
      this.error = null

      if (this.rangeUnderflow() || this.rangeOverflow()) {
        this.invalid()
      }

      this.$refs.input.setCustomValidity(this.error || "")

      return this.error === null
    }
  }
}
</script>

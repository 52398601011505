<template>
  <div :class="{ 'w-full' : editing || !!couponDiscount}">
    <div v-if="editing" key="input">
      <div v-if="error" class="error-message">
        {{ error }}
      </div>

      <div class="flex items-center justify-between relative w-full @md:mt-0 mt-2">
        <input
          ref="input"
          type="text"
          class="input focus:shadow-none focus:shadow-inherit focus:outline-none focus:ring-4 focus:ring-black/5 focus:border-black/5"
          v-model="value"
          :aria-label="t('.placeholder')"
          :placeholder="t('.placeholder')"
          @keydown.enter.prevent="apply"
          autofocus="true"
        />

        <button
          type="button"
          class="btn-main py-0 h-8 px-3 w-auto inset-y-auto text-sm inline-flex items-center leading-tight shadow-sm absolute right-[5px]"
          @click="apply"
        >
          <span class="-top-px relative">{{ t(".apply") }}</span>
        </button>
      </div>
    </div>

    <div v-else key="couponDiscount" class="w-full">
      <div v-if="couponDiscount" class="flex items-center justify-between flex-wrap pt-2">
        <div class="flex-1 flex items-center max-w-xs">
          <div
            class="btn-main inline-flex items-center h-8 leading-none py-0 px-2 text-base shadow-sm rounded-r-none focus:ring-transparent active:ring-transparent">
            <SvgIcon
              name="tag"
              class="fill-current mr-1 w-4 h-4"
            />
            <span class="opacity-80 truncate leading-normal max-w-[13rem]">{{ value }}</span>
          </div>

          <button
            type="button"
            class="h-8 w-7 flex items-center justify-center rounded-r rounded-l-none btn-main focus:ring-transparent active:ring-transparent"
            :aria-label="t('.remove')"
            :title="t('.remove')"
            @click="reset"
          >
            <SvgIcon name="close" class="fill-current w-3 h-3 flex-shrink-0" />
          </button>
        </div>

        <p class="opacity-80">{{ t(".coupon_applied") }}</p>
      </div>

      <button v-if="!couponDiscount && showCouponInput" type="button" class="text-sm font-system antialiased duration-200 transition underline bg-transparent text-theme-content hover:text-theme-content" @click="editing = true">
        {{ t(".edit") }}
      </button>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "helpers/currency"
import { mapState, mapGetters } from "vuex"
import SvgIcon from './icons/SvgIcon.vue'

export default {
  components: {
    SvgIcon
  },

  props: {
    name: String
  },

  data: function () {
    const coupon = this.$store.state.checkout.coupon

    return {
      editing: false,
      error: null,
      value: coupon ? coupon.value : null
    }
  },

  mounted() {
    this.previewChannel = new BroadcastChannel("website-form")
    this.previewChannel.onmessage = this.toggle.bind(this)
  },

  beforeDestroy() {
    this.previewChannel.close()
  },

  computed: {
    coupon() {
      return {
        attribute: this.name,
        value: this.value
      }
    },

    ...mapState("checkout", [
      "showCouponInput"
    ]),

    ...mapGetters("checkout", [
      "couponDiscount"
    ])
  },

  methods: {
    apply(event) {
      if (this.value) {
        this.$store.dispatch("checkout/updateCoupon", this.coupon).then(() => {
          this.editing = false
          this.error = null
        }).catch(error => {
          this.error = this.t(".error")
        })
      }
    },

    formatCurrency,

    reset() {
      this.value = null
      this.$store.dispatch("checkout/updateCoupon", null)
    },

    toggle(event) {
      const { input } = event.data

      if (input.id === "site_hide_coupon_field") {
        this.$store.commit("checkout/setShowCouponInput", !input.checked)
      }
    }
  },

  watch: {
    editing(isEditing) {
      if (isEditing) this.$nextTick(() => this.$refs.input.focus())
    }
  }
}
</script>

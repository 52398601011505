import formDataEntries from "form-data-entries"

const EMOJI_FLAG_OFFSET = 127397

const RAILS_FIELD_NAME = /([a-z_]+)\[([a-z_]+)\]/

export function focusWithin(element) {
  element.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').focus()
}

export function generateFlagEmoji(country) {
  return country.replace(/[A-Z]/g, char =>
    String.fromCodePoint(char.charCodeAt(0) + EMOJI_FLAG_OFFSET)
  )
}

export function getSearchParam(name) {
  const searchParams = new URLSearchParams(document.location.search)
  return searchParams.get(name)
}

export function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  if(element) {
    return element.getAttribute("content")
  }
}

export function getOrdinal(number) {
  return `${ number }${ [,'st','nd','rd'][number / 10%10^1 && number%10] || 'th' }`
}

export function serializeForm(form) {
  let formData = {}

  for (let [name, value] of formDataEntries(form)) {
    let result = name.match(RAILS_FIELD_NAME)

    if (result) {
      let [, model, attribute] = result
      formData[model] = formData[model] || {}
      formData[model][attribute] = value
    } else {
      formData[name] = value
    }
  }

  return formData
}

export function selectedOptions(options) {
  return options
    .filter(option => option.checked)
    .map(option => option.labels[0].textContent)
}

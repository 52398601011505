import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-start", this.disable)
    this.element.addEventListener("turbo:submit-end", this.enable)
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-start", this.disable)
    this.element.removeEventListener("turbo:submit-end", this.enable)
  }

  disable({ detail: { formSubmission: { submitter } } }) {
    if (submitter.dataset.disableWith) {
      submitter.dataset.enableWith = submitter.value
      submitter.value = submitter.dataset.disableWith
    }
  }

  enable({ detail: { formSubmission: { submitter }, success } }) {
    if (success && submitter.dataset.enableWithSuccess === "false") return

    if (submitter.value === submitter.dataset.disableWith) {
      submitter.value = submitter.dataset.enableWith
    }
  }
}

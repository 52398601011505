<template>
  <form novalidate :class="{ invalid: errors.length }" @submit.prevent="submit">
    <div v-for="error in errors" class="error-message">
      {{ error }}
    </div>

    <MailingAddress
      v-model="address"
      v-bind="$attrs"
      :required="requireMailingAddress"
      @invalid="errors.push($event)"
    />

    <PhoneNumber
      v-model="phoneNumber"
      :required="requirePhoneNumber"
      @invalid="errors.push($event)"
    />

    <button class="btn-main btn-expanded" type="submit">{{ t(".submit") }}</button>
  </form>
</template>

<script>
import MailingAddress from "./MailingAddress"
import PhoneNumber from "./PhoneNumber"
import { decamelizeKeys } from "humps"

export default {
  components: {
    MailingAddress,
    PhoneNumber
  },

  props: {
    requireMailingAddress: Boolean,
    requirePhoneNumber: Boolean,
    savedAddress: Object,
    savedPhoneNumber: String
  },

  inheritAttrs: false,

  data() {
    return {
      address: this.savedAddress,
      phoneNumber: this.savedPhoneNumber,
      errors: []
    }
  },

  methods: {
    submit(event) {
      this.errors = []

      if (event.target.checkValidity()) {
        let attributes = { ...decamelizeKeys(this.address), phone_number: this.phoneNumber }

        this.$http.patch("/account/address", { json: { member: attributes } }).json().then(json => {
          Turbo.cache.clear()
          Turbo.visit(json.redirect_to, { action: "replace", frame: "member-account" })
        })
      }
    }
  }
}
</script>

<template>
  <div>
    <div v-if="issueCount == 1 && !complete" class="bg-black bg-opacity-75 fixed h-full inset-0 pt-56 w-full z-50">
      <div class="bg-white sm:max-w-xs mx-auto overflow-y-auto pt-8 px-6 pb-6 rounded-lg shadow-xl text-center w-11/12">
        <h1 class="font-semibold font-sans leading-tight mb-3 text-gray-800 text-lg lg:text-xl">
          {{ t(".title") }}
        </h1>
        <p class="text-gray-700 mb-6">
          {{ t(".description") }}
        </p>
        <a class="btn-main btn-expanded" href="/cloudflare/challenge" target="_blank" @click.prevent="open">
          {{ t(".continue") }}
        </a>
      </div>
    </div>

    <div v-if="issueCount > 1" class="error-message mt-2 mb-4">
      {{ t(".error") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    issueCount: Number
  },

  data() {
    return {
      complete: false
    }
  },

  watch: {
    issueCount(value) {
      if (value > 1) this.$emit("error")
    }
  },

  mounted() {
    window.document.addEventListener("cloudflare-challenge:complete", this.done, false)
  },

  beforeDestroy() {
    window.document.removeEventListener("cloudflare-challenge:complete", this.done, false)
  },

  methods: {
    open(event) {
      this.challengeWindow = window.open(event.target.href, "_blank")
    },

    done(event) {
      this.challengeWindow.close()
      this.complete = true
      this.$emit("complete")
    }
  }
}
</script>

<template>
  <div class="mb-3">
    <div class="flex items-start justify-between">
      <slot name="header">
        <p class="mb-1 opacity-85">{{ t(".payment_method") }}</p>
      </slot>
    </div>

    <div class="flex items-center justify-between px-4 text-gray-700 bg-gray-100 border rounded font-mono capitalize">
      <div class="flex items-center justify-between py-4">
        <img class="flex-shrink-0 w-8 h-auto mr-2" :src="logo"/>
        <div class="text-sm">&#183;&#183;&#183;&#183;{{ last4 }} <span class="font-mono text-xs text-gray-500 relative -top-px">{{ month }}/{{ year }}</span></div>
      </div>
      <slot name="actions">
        <button type="button" @click="$emit('change')" class="text-sm underline hover:no-underline focus:outline-none">
          <span class="sr-only">{{ t(".change_saved_payment_method") }}</span>
          <svg class="w-4 h-auto text-gray-500 fill-current hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><title>{{ t(".change_payment_method") }}</title><path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z"></path></svg>
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    brand: String,
    expMonth: Number,
    expYear: Number,
    last4: String
  },

  data: function() {
    return {
      month: this.expMonth.toString().padStart(2, "0"),
      year: this.expYear.toString().slice(-2)
    }
  },

  computed: {
    logo: function() {
      try {
        return require(`../../assets/images/icons/cards/${this.brand}.svg`)
      } catch (err) {
        return require(`../../assets/images/icons/cards/other.svg`)
      }
    },
  }
}
</script>

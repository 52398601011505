import { Controller } from "@hotwired/stimulus"
import http from "helpers/http"

export default class extends Controller {
  static targets = ["promotionForm"]

  static values = {
    teamMemberId: Number,
    teamSubscriptionId: Number,
    url: String
  }

  showPromotionForm() {
    this.fetch(this.urlValue)
  }

  hidePromotionForm() {
    this.promotionFormTarget.innerHTML = ""
  }

  // private

  fetch(url) {
    http.get(url)
      .json()
      .then(response => {
        this.promotionFormTarget.innerHTML = response.body
      })
  }
}

<template>
  <form
    class="checkout-form h-full"
    data-controller="website-form"
    id="checkout_form"
    method="post"
    novalidate
    :class="{ invalid: errors.length }"
    @submit.prevent="submit"
  >
    <MemberLayout>
      <template v-slot:header>

        <CheckoutItem v-bind="item" />

        <div v-if="referrerName" class="lg:fixed lg:top-2 lg:right-2 text-center mb-4">
          <span class="text-sm rounded-full pl-2 pr-3 py-1 antialiased font-semibold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1 shrink-0" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
            <span>{{ t(".referred_by", { name: referrerName }) }}</span>
          </span>
        </div>

        <div class="h-px w-full my-3 @2xl:hidden block bg-theme-content opacity-10"></div>
        <div class="flex items-center justify-between flex-wrap w-full relative @2xl:block">
          <h2 class="@2xl:hidden font-bold">{{ item.name }}</h2>

          <div class="block w-full @2xl:text-base @2xl:mb-2 opacity-70 -mt-2 text-sm mb-1" v-if="item.teamPlan && !item.allowAdditionalSeatPurchases">
            {{ t(".included_seats", { count: item.includedSeats, smart_count: item.includedSeats }) }}
          </div>

          <CouponInput name="coupon_code" />
        </div>

        <PaymentDetails :paymentDate="paymentDate" class="hidden md:block @2xl:pt-0 pt-3" />
      </template>

      <TeamSeatsInput
          v-if="item.teamPlan && item.allowAdditionalSeatPurchases"
          v-bind="item"
          class="mb-4"
          @change="totalSeats = $event"
        />

      <MemberPrice
        v-if="item.allowMembersToSetPrice"
        :label="item.trial ? t('.choose_what_you_pay_after_trial') : t('.choose_what_you_pay')"
        @change="memberPrice = $event"
      />

      <PaymentForm
        ref="paymentForm"
        v-bind="$attrs"
        :buttonLabel="buttonLabel"
        :express="true"
        :formData="formData"
        :requireCard="requireCard"
        :requireMailingAddress="requireMailingAddress"
        :requirePhoneNumber="requirePhoneNumber"
        :showTax="false"
        :showTotal="!item.trial || item.trial.paid"
        @error="errors.push($event)"
        @success="handleRedirect"
      >

        <p v-if="!currentMember" class="memberful-form-note">
          {{ t(".already_have_an_account") }}
          <a :href="signInPath" data-website-preview-target="disable">
            {{ t(".sign_in") }}
          </a>
        </p>

        <div v-for="error in errors" class="error-message">
          {{ error }}
        </div>

        <div v-if="!currentMember" class="mb-3">
          <input
            autofocus
            v-model="name"
            required
            autocomplete="name"
            class="input"
            minlength=2
            name="checkout[full_name]"
            :aria-label="t('.full_name')"
            :placeholder="t('.full_name')"
            @invalid.prevent="errors.push(t('.name_too_short'))"
          />
        </div>

        <EmailInput
          name="email"
          v-if="!currentMember"
          v-model="email"
          :placeholder="t('.email_address')"
          @invalid="errors.push($event)"
        />

        <PhoneNumber
          v-if="requirePhoneNumber"
          v-model="phoneNumber"
          @invalid="errors.push($event)"
        />

        <MailingAddress
          v-if="requireMailingAddress"
          v-model="address"
          :expandable="true"
          :googlePlacesApiKey="googlePlacesApiKey"
          @invalid="errors.push($event)"
        />
      </PaymentForm>

      <div class="-mx-6 mt-6 @2xl:mt-0 p-0 @2xl:shadow-none @2xl:bg-none" :class="{ 'shadow-inner bg-gradient-to-b from-gray-100 to-transparent' : item.autorenew && !itemIsFixedPlan}">
        <PaymentDetails :paymentDate="paymentDate" class="@2xl:hidden block px-6 @2xl:px-0 pt-3" />
        <div class="text-sm text-center text-gray-600 pt-2">
          <p v-if="item.autorenew && !itemIsFixedPlan" class="mt-3">{{ t(".cancel_anytime") }}</p>
        </div>
      </div>
    </MemberLayout>
  </form>
</template>

<script>
import CheckoutItem from "./CheckoutItem"
import CouponInput from "./CouponInput"
import EmailInput from "./EmailInput"
import MailingAddress from "./MailingAddress"
import MemberLayout from "./MemberLayout"
import MemberPrice from "./MemberPrice"
import PaymentDetails from "./PaymentDetails"
import PaymentForm from "./PaymentForm"
import PhoneNumber from "./PhoneNumber"
import TeamSeatsInput from "./TeamSeatsInput"

import getTrackingParameters from "helpers/tracking_parameters"
import { decamelizeKeys } from "humps"
import { mapState, mapGetters } from "vuex"

export default {
  components: {
    CheckoutItem,
    CouponInput,
    EmailInput,
    MailingAddress,
    MemberLayout,
    MemberPrice,
    PaymentDetails,
    PaymentForm,
    PhoneNumber,
    TeamSeatsInput
  },

  inheritAttrs: false,

  props: {
    currentMember: Boolean,
    defaultCountry: String,
    googlePlacesApiKey: String,
    referralCode: String,
    referrerName: String,
    requireMailingAddress: Boolean,
    requirePhoneNumber: Boolean
  },

  data() {
    return {
      address: this.requireMailingAddress ? { country: this.defaultCountry } : null,
      email: null,
      errors: [],
      name: null,
      memberPrice: null,
      phoneNumber: null,
      totalSeats: null,
      trackingParams: getTrackingParameters(location.href)
    }
  },

  computed: {
    buttonLabel() {
      if (this.item.trial) {
        if (this.item.trial.paid) {
          return this.t(".start_your_trial")
        } else {
          return this.t(".start_your_free_trial")
        }
      } else {
        return this.t(".place_your_order")
      }
    },

    itemIsFixedPlan() {
      return this.item.renewalCount
    },

    formData() {
      return {
        email: this.email,
        full_name: this.name,
        phone_number: this.phoneNumber,
        referral_code: this.referralCode,
        tracking_params: this.trackingParams,
        ...decamelizeKeys(this.address)
      }
    },

    paymentDate() {
      if (this.item.trial && !this.item.trial.paid) return this.item.trial.endDate
    },

    requireCard() {
      if (this.item.trial) return this.item.trial.requireCard
      if (this.couponDiscount) return this.couponDiscount.requireCard

      return true
    },

    signInPath() {
      let params = new URLSearchParams(window.location.search)

      if (this.coupon) params.set("coupon", this.coupon.value)
      if (this.totalSeats) params.set("members", this.totalSeats)
      if (this.memberPrice) params.set("price", this.memberPrice)

      return `/checkout/sign_in?${params.toString()}`
    },

    ...mapState("checkout", [
      "coupon",
      "item"
    ]),

    ...mapGetters("checkout", [
      "couponDiscount"
    ])
  },

  methods: {
    handleRedirect(response) {
      if (response.turbo) {
        Turbo.cache.clear()
        Turbo.visit(response.redirect_to, { action: "replace" })
      } else {
        Memberful.redirectTo(response.redirect_to)
      }
    },

    submit() {
      this.errors = []

      if (this.$el.checkValidity()) {
        this.$refs.paymentForm.submit()
      }
    }
  },
}
</script>

<template>
  <div v-if="showErrors">
    <div v-for="error in errors" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
const objEmpty = obj => Object.keys(obj).every(key => obj[key] === undefined);

export default {
  props: ["errors"],
  computed: {
    showErrors() {
      return !objEmpty(this.errors)
    }
  }
}
</script>

import { decamelizeKeys } from "humps"
import http from "helpers/http"

const state = () => ({
  baseCouponDiscount: null,
  baseReferralDiscount: null,
  baseRetentionDiscount: null,
  billingAddress: null,
  coupon: null,
  couponPath: null,
  item: {
    price: 0
  },
  lineItems: [],
  memberPrice: null,
  minimumChargeAmount: null,
  resource: null,
  showCouponInput: null,
  tax: null,
  taxId: null
})

const getters = {
  adjustedDiscounts: ({ baseCouponDiscount, baseReferralDiscount, baseRetentionDiscount, minimumChargeAmount }, { subtotal }) => {
    let newSubtotal = subtotal
    let discounts = {}

    if (baseCouponDiscount) {
      discounts.couponDiscount = { ...baseCouponDiscount }
    }

    if (baseReferralDiscount) {
      discounts.referralDiscount = { ...baseReferralDiscount }
    }

    if (baseRetentionDiscount) {
      discounts.retentionDiscount = { ...baseRetentionDiscount }
    }

    // Adjust discounts to not exceed the subtotal
    for (let [_, discount] of Object.entries(discounts)) {
      if (discount.amount > newSubtotal) {
        discount.amount = newSubtotal
      }
      newSubtotal -= discount.amount
    }

    // Adjust discounts for minimum charge amount
    if (newSubtotal > 0 && newSubtotal < minimumChargeAmount) {
      const sortedDiscounts = Object.entries(discounts).sort(([,a], [,b]) => a.amount - b.amount);

      for (let [_, discount] of sortedDiscounts) {
        const shortfallToMinimumCharge = minimumChargeAmount - newSubtotal;

        if (discount.amount <= shortfallToMinimumCharge) {
          newSubtotal += discount.amount
          discount.amount = 0
        } else {
          discount.amount -= shortfallToMinimumCharge
          break
        }
      }
    }

    return discounts
  },

  couponDiscount: ({}, { adjustedDiscounts }) => {
    return adjustedDiscounts.couponDiscount
  },

  discountSubtotal: ({} , { subtotal, totalDiscount }) => {
    return subtotal - totalDiscount
  },

  displayTaxAmount: ({ tax }) => {
    return tax && tax.amount > 0 && !tax.inclusive && !tax.vat
  },

  displayTaxRate: ({ tax }) => {
    return tax && tax.amount > 0 && !tax.reverseCharge && (tax.inclusive || tax.vat)
  },

  freeTrial({ item }) {
    return item.trial && !item.trial.paid
  },

  paidTrial({ item }) {
    return item.trial && item.trial.paid
  },

  hasCustomRenewalPrice({ item }) {
    return item.renewalPrice && item.renewalPrice > 0
  },

  hasCustomRenewalFrequency({ item }) {
    return item.intervalCount !== 1
  },

  limitedDiscount({ baseCouponDiscount }) {
    return baseCouponDiscount && baseCouponDiscount.couponType === "limited"
  },

  oneTimeDiscount({ baseCouponDiscount }) {
    return baseCouponDiscount && baseCouponDiscount.couponType === "one_time"
  },

  recurringDiscount({ baseCouponDiscount }) {
    return baseCouponDiscount && baseCouponDiscount.couponType === "recurring"
  },

  itemPrice: ({ memberPrice }, { minimumPrice }) => {
    return Math.max(memberPrice, minimumPrice)
  },

  minimumPrice: ({ item, lineItems }) => {
    return lineItems.reduce((price, lineItem) => price + lineItem.price, item.price)
  },

  referralDiscount: ({}, { adjustedDiscounts }) => {
    return adjustedDiscounts.referralDiscount
  },

  retentionDiscount: ({}, { adjustedDiscounts }) => {
    return adjustedDiscounts.retentionDiscount
  },

  renewalPrice: ({ item }, { hasCustomRenewalPrice, itemPrice, minimumPrice }) => {
    if (hasCustomRenewalPrice) {
      return minimumPrice + (item.renewalPrice - item.price)
    } else {
      return itemPrice
    }
  },

  renewalPriceDiscount: ({ baseCouponDiscount }, { hasCustomRenewalPrice, paidTrial, recurringDiscount }) => {
    if (recurringDiscount) {
      if (hasCustomRenewalPrice || paidTrial) {
        return baseCouponDiscount.amountForRenewal
      } else {
        return baseCouponDiscount.amount
      }
    }

    return 0
  },

  renewalPriceTotal: ({}, { renewalPrice, renewalPriceDiscount }) => {
    return renewalPrice - renewalPriceDiscount
  },

  renewalPriceAfterTrial: ({ item }, { itemPrice }) => {
    if (!item.trial) return

    return itemPrice
  },

  renewalPriceAfterTrialDiscount: ({ baseCouponDiscount }, { freeTrial, oneTimeDiscount, paidTrial }) => {
    if (baseCouponDiscount) {
      if (paidTrial & !oneTimeDiscount) {
        return baseCouponDiscount.amountAfterTrial
      }

      if (freeTrial) {
        return baseCouponDiscount.amount
      }
    }

    return 0
  },

  renewalPriceAfterTrialTotal: ({}, { renewalPriceAfterTrial, renewalPriceAfterTrialDiscount }) => {
    return renewalPriceAfterTrial - renewalPriceAfterTrialDiscount
  },

  renewalPriceWithLimitedDiscount: ({}, { limitedDiscount, renewalPrice }) => {
    if (!limitedDiscount) return 0

    return renewalPrice
  },

  renewalPriceWithLimitedDiscountDiscount: ({ baseCouponDiscount }, { freeTrial, hasCustomRenewalPrice, paidTrial }) => {
    if (baseCouponDiscount) {
      if (hasCustomRenewalPrice) {
        return baseCouponDiscount.amountForRenewal
      }

      if (paidTrial) {
        return baseCouponDiscount.amountAfterTrial
      }

      if (freeTrial) {
        return baseCouponDiscount.amount
      }
    }

    return 0
  },

  renewalPriceWithLimitedDiscountTotal: ({}, { renewalPriceWithLimitedDiscount, renewalPriceWithLimitedDiscountDiscount }) => {
    return renewalPriceWithLimitedDiscount - renewalPriceWithLimitedDiscountDiscount
  },

  serialize: ({ coupon, item, lineItems, memberPrice, resource }) => {
    let data = {
      [item.attribute]: item.id
    }

    if (coupon) {
      data[coupon.attribute] = coupon.value
    }

    lineItems.forEach(lineItem => {
      data[lineItem.name] = lineItem.value
    })

    return (formData) => {
      let body = Object.assign({}, data, formData)

      if (resource) {
        body = { [resource]: body }
      }

      return { ...body, price_cents: memberPrice }
    }
  },

  subtotal: ({ item }, { itemPrice }) => {
    return item.trial?.paid ? item.trial.price : itemPrice
  },

  taxable: ({ billingAddress: address, item }, { subtotal }) => {
    if (!item.taxable || subtotal === 0) return false

    if (address.country === "US") {
      return Boolean(address.postalCode) && Boolean(address.state)
    } else if (address.country === "CA") {
      return Boolean(address.state)
    } else {
      return true
    }
  },

  taxRate: ({ tax }) => {
    const formatOptions = { style: "percent", maximumFractionDigits: 2 }
    return tax.rate.toLocaleString(memberful.config.locale, formatOptions)
  },

  total: ({ discount, referralDiscount, minimumChargeAmount, tax }, getters) => {
    let total = getters.subtotal - getters.totalDiscount

    if (tax) {
      if (!tax.inclusive) {
        return total + tax.amount
      } else if (tax.reverseCharge) {
        return total - tax.amount
      }
    }

    return total
  },

  totalDiscount: ({}, { adjustedDiscounts }) => {
    return Object.values(adjustedDiscounts).reduce((total, discount) => total + discount.amount, 0)
  }
}

const actions = {
  fetchDiscount({ commit, getters, state }, coupon) {
    if (coupon) {
      let data = getters.serialize({ [coupon.attribute]: coupon.value })

      return http.post(state.couponPath, { json: data }).json().then(json => {
        commit("setDiscount", json.discount)
      })
    } else {
      if (state.baseCouponDiscount) {
        commit("setDiscount", null)
      }

      return Promise.resolve()
    }
  },

  fetchTax({ commit, getters, state }) {
    if (getters.taxable) {
      let data = {
        amount: getters.discountSubtotal,
        taxCategory: state.item.taxCategory,
        taxId: state.taxId,
        ...state.billingAddress,
      }

      return http.post("/taxes", { json: decamelizeKeys(data) }).json().then(json => {
        commit("setTax", json.tax)
      })
    } else {
      commit("setTax", null)

      return Promise.resolve()
    }
  },

  updateBillingAddress({ commit, dispatch }, billingAddress) {
    commit("setBillingAddress", billingAddress)

    return dispatch("fetchTax")
  },

  updateCoupon({ commit, dispatch }, coupon) {
    return dispatch("fetchDiscount", coupon).then(() => {
      commit("setCoupon", coupon)
      dispatch("fetchTax")
    })
  },

  updateLineItem({ commit, dispatch, state }, lineItem) {
    commit("setLineItem", lineItem)

    if (!state.memberPrice) {
      dispatch("fetchDiscount", state.coupon).then(() => dispatch("fetchTax"))
    }
  },

  updateMemberPrice({ commit, dispatch, state }, memberPrice) {
    commit("setMemberPrice", memberPrice)

    dispatch("fetchDiscount", state.coupon).then(() => dispatch("fetchTax"))
  },

  updateTaxId({ commit, dispatch }, taxId) {
    commit("setTaxId", taxId)

    dispatch("fetchTax")
  }
}

const mutations = {
  setBillingAddress(state, billingAddress) {
    state.billingAddress = billingAddress
  },

  setCoupon(state, coupon) {
    state.coupon = coupon
  },

  setDiscount(state, discount) {
    state.baseCouponDiscount = discount
  },

  setInitialState(state, initialState) {
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = value
    }
  },

  setLineItem(state, { name, price = 0, value }) {
    let option = state.lineItems.find(option => option.name === name)

    if (option) {
      option.price = price
      option.value = value
    } else {
      state.lineItems.push({ name, price, value })
    }
  },

  setMemberPrice(state, memberPrice) {
    state.memberPrice = memberPrice
  },

  setShowCouponInput(state, value) {
    state.showCouponInput = value
  },

  setTax(state, tax) {
    state.tax = tax
  },

  setTaxId(state, taxId) {
    state.taxId = taxId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { serializeForm } from "helpers"
import { Controller } from "@hotwired/stimulus"
import { createStore } from "store"
import Vue from "vue"

const VueForm = (component) => class extends Controller {
  static targets = ["mount"]

  connect() {
    if (window.Turbo) { Turbo.cache.exemptPageFromPreview() }

    const el = this.mountTarget
    const props = JSON.parse(this.element.dataset.props)

    props.action = this.element.action
    props.formData = {}

    const store = createStore(props.store)
    delete props.store

    const listeners = {}

    if (typeof this.errorCallback === "function") {
      listeners.error = (value) => this.errorCallback(value)
    }

    if (typeof this.successCallback === "function") {
      listeners.success = (value) => this.successCallback(value)
    }

    this.vm = new Vue({
      el,
      data: props,
      store,
      render: function(createElement) {
        return createElement(component, {
          props: this.$data,
          on: listeners,
          ref: "form"
        })
      }
    })
  }

  disconnect() {
    this.vm.$destroy()
  }

  submit(event) {
    event.preventDefault()

    if (this.element.checkValidity()) {
      this.element.classList.remove("invalid")
      this.vm.$refs.form.submit(serializeForm(this.element))
    } else {
      this.element.classList.add("invalid")
    }
  }
}

export default VueForm

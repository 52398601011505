<template>
  <div class="mb-6 text-center zoomIn">
    <div class="text-center flex items-center justify-center">
      <svg :class="iconSize" class="text-green-500 stroke-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><path d="M9 12l2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
    </div>

    <div class="mt-4">
      <h2 class="select-none text-lg opacity-75 text-center w-full block member-page-title">{{ message }}</h2>
      <p class="leading-snug opacity-75">{{ copy }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'order-complete',
    data() {
      return {
        iconSize: 'w-12 h-12'
      }
    },
    props: {
      message: {
        type: String,
        required: true
      },
      copy: {
        type: String,
        required: true
      }
    }
  }
</script>

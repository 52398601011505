<template>
  <div>
    <div v-if="!value" class="p-4 bg-gray-50 border-t border-gray-300">
      <p class="mb-3 text-center text-sm text-gray-700">{{ t('.select_preferred_app') }}</p>

      <div v-for="(platform, index) in platforms">
        <p v-if="platforms.length > 1" class="relative text-center before:absolute before:left-0 before:top-1/2 before:w-full before:content[''] before:border-b before:border-gray-400">
          <span class="text-base text-gray-600 bg-gray-50 inline-block px-3 relative z-10">{{ platform.label }}</span>
        </p>

        <div class="grid grid-cols-4 gap-4 w-full pt-2 pb-4">
          <div v-for="app in platform.apps" v-if="shouldDisplayApp(app)" class="flex w-full leading-tight">
            <button @click="select(app, platform, index == 0)" class="flex flex-col items-center w-full py-2 text-left text-sm rounded-lg hover:bg-gray-200" :class="{ 'cursor-alias': index == 0 }">
              <img :src="images(`./${app.image}`)" class="w-9 h-9 mb-2 rounded">
              <span class="block max-h-8 overflow-hidden text-center text-xs text-gray-700 leading-3">{{ appLabel(app) }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="value" class="px-4 py-4 text-center bg-gray-50 border-t border-gray-300">
      <p class="text-center text-sm text-gray-700">{{ t('.scan_qr_code') }}</p>

      <div class="inline-flex justify-center items-center my-4 mx-auto p-4 bg-white rounded-lg shadow">
        <div class="flex flex-col justify-center items-center w-20 h-28 mr-4">
          <span class="text-gray-700 leading-none">{{ value.platform }}</span>
          <img :src="images(`./${value.image}`)" class="w-12 h-12 my-2 rounded">
          <span class="block max-h-10 overflow-hidden text-center text-sm text-gray-700 leading-3">{{ appLabel(value) }}</span>
        </div>

        <QrCode :value="link(value)" :size="112" />
      </div>

      <button @click="$emit('input', null)" class="block mx-auto text-sm underline">{{ t('.select_another_app') }}</button>
    </div>

    <div class="p-4 border-t border-gray-300">
      <p class="text-center text-sm text-gray-700">{{ t(".paste_this_link") }}</p>

      <div data-controller="clipboard" class="flex items-center justify-start mt-4">
        <div class="flex-1">
          <input :value="feed.url" type="text" data-clipboard-target="source" class="flex-1 w-full py-2 rounded-r-none feed-copy__url input" readonly :aria-label="t('.feed_url')">
        </div>

        <button data-action="clipboard#copy" class="font-system bg-white text-gray-700 border border-gray-400 hover:text-gray-800 hover:shadow-none hover:border-gray-400 focus:bg-white focus:text-gray-800 focus:shadow-none focus:border-gray-400 h-[42px] flex items-center justify-center w-10 rounded-r border-l-0 hover:bg-gray-50" :aria-label="t('.copy')">
          <span class="sr-only">{{ t(".copy") }}</span>

          <svg class="w-5 h-5 stroke-current hover:opacity-85" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>{{ t(".copy") }}</title>
            <g fill="none">
              <path d="M8 5H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1M8 5a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2M8 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2m0 0h2a2 2 0 0 1 2 2v3m2 4H10m0 0l3-3m-3 3l3 3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import platforms from "../data/podcast_app_select.json"
import QrCode from "qrcode.vue"
const images = require.context("../images/apps", false, /\.(png|svg)$/)

export default {
  props: {
    feed: Object,
    userAgent: String,
    value: Object
  },

  components: {
    QrCode
  },

  beforeCreate() {
    this.images = images
  },

  computed: {
    platforms() {
      let userAgent = this.userAgent

      if (!["windows", "mac", "ios", "android"].includes(this.userAgent)) {
        userAgent = "windows"
      }

      return platforms.filter(platform => platform.userAgents.includes(userAgent))
    }
  },

  methods: {
    appLabel(app) {
      if (app.name == "Other apps") {
        return this.t(`.other_apps`)
      } else {
        return app.name
      }
    },

    encodeUrl(url, encoding) {
      switch(encoding) {
        case "base64": return btoa(url)
        case "percent-encoding": return encodeURIComponent(url)
      }
    },

    link(app) {
      if (app.scheme === "spotify") return this.feed.spotify

      let url

      if (app.encoding) {
        url = this.encodeUrl(this.feed.url, app.encoding)
      } else {
        url = this.removeScheme(this.feed.url)
      }

      return `${app.scheme}${url}`
    },

    removeScheme(url) {
      return url.split("//")[1]
    },

    select(app, platform, open) {
      if (open) {
        const link = this.link(app)
        window.open(link, link.startsWith("http") ? "_blank" : "_self")
      } else {
        this.$emit('input', { ...app, platform: platform.label })
      }
    },

    shouldDisplayApp(app) {
      return (app.scheme !== 'spotify' || this.feed.spotify) && (!this.feed.video || app.supportsVideo);
    }
  }
}
</script>

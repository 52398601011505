import { render, staticRenderFns } from "./SavedCard.vue?vue&type=template&id=536fe0f8"
import script from "./SavedCard.vue?vue&type=script&lang=js"
export * from "./SavedCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "SavedCard.vue"
export default component.exports
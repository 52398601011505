<template>
  <div>
    <div v-if="showTitle" class="mb-6 text-center zoomIn">
      <div class="text-center flex items-center justify-center">
        <svg :class="iconSize" class="text-green-500 stroke-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><path d="M9 12l2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
      </div>
      <h2 class="select-none text-lg opacity-75 text-center w-full block">
        {{ checkout ? t(".order_complete") : t(".account_created") }}
      </h2>
    </div>

    <div v-show="showCustomFields">
      <h3 class="font-semibold text-lg mb-3 text-gray-800">{{ t(".prompt", { smart_count: customFields.length }) }}</h3>

      <CustomFields
        :answers="answers"
        :fields="customFields"
        @complete="hideFields"
      />
    </div>

    <div v-show="showBenefits && !showCustomFields">
      <div v-if="showDiscord" class="mb-6">
        <h3 class="uppercase text-gray-900 font-semibold font-system text-xs tracking-wide mb-3">{{ t(".community_label") }}</h3>

        <a :href="discordURL" target="_blank" class="btn btn-integration">
          <span class="flex border-r border-gray-300 pr-3 mr-3 text-[#5865F2]">
            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="fill-current w-5 h-5">
              <path d="M20.222 0c1.406 0 2.54 1.137 2.607 2.475V24l-2.677-2.273-1.47-1.338-1.604-1.398.67 2.205H3.71c-1.402 0-2.54-1.065-2.54-2.476V2.48C1.17 1.142 2.31.003 3.715.003h16.5L20.222 0zm-6.118 5.683h-.03l-.202.2c2.073.6 3.076 1.537 3.076 1.537-1.336-.668-2.54-1.002-3.744-1.137-.87-.135-1.74-.064-2.475 0h-.2c-.47 0-1.47.2-2.81.735-.467.203-.735.336-.735.336s1.002-1.002 3.21-1.537l-.135-.135s-1.672-.064-3.477 1.27c0 0-1.805 3.144-1.805 7.02 0 0 1 1.74 3.743 1.806 0 0 .4-.533.805-1.002-1.54-.468-2.14-1.404-2.14-1.404s.134.066.335.2h.06c.03 0 .044.015.06.03v.006c.016.016.03.03.06.03.33.136.66.27.93.4.466.202 1.065.403 1.8.536.93.135 1.996.2 3.21 0 .6-.135 1.2-.267 1.8-.535.39-.2.87-.4 1.397-.737 0 0-.6.936-2.205 1.404.33.466.795 1 .795 1 2.744-.06 3.81-1.8 3.87-1.726 0-3.87-1.815-7.02-1.815-7.02-1.635-1.214-3.165-1.26-3.435-1.26l.056-.02zm.168 4.413c.703 0 1.27.6 1.27 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334.002-.74.573-1.338 1.27-1.338zm-4.543 0c.7 0 1.266.6 1.266 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334 0-.74.57-1.338 1.27-1.338z"/>
            </svg>
          </span>
          <span>{{ t(".join_discord") }}</span>
          <svg class="stroke-gray-500 w-4 h-4 fill-transparent ml-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 6H6C4.89543 6 4 6.89543 4 8V18C4 19.1046 4.89543 20 6 20H16C17.1046 20 18 19.1046 18 18V14M14 4H20M20 4V10M20 4L10 14" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
      </div>

      <div v-if="showFeeds" class="mb-6">
        <h3 class="uppercase text-gray-900 font-semibold font-system text-xs tracking-wide mb-3">{{ t(".podcasts") }}</h3>
        <Feeds
          :collapsed="showDiscord"
          :feeds="feeds"
          :userAgent="userAgent"
        />
      </div>

      <div class="text-center continue-to-site">
        <a ref="link" :href="redirect" class="btn btn-default btn-sm" data-turbo-stream>
          <span class="mr-1">{{ t(".continue_to_site") }}</span>
          <SvgIcon name="chevron-right" class="fill-current w-3 h-3" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CustomFields from "./CustomFields"
import Feeds from "./Feeds"
import SvgIcon from "./icons/SvgIcon.vue"

export default {
  components: {
    CustomFields,
    Feeds,
    SvgIcon
  },

  props: {
    answers: {
      type: Object,
      default: () => { return {} }
    },
    discordURL: String,
    feeds: {
      type: Array,
      default: () => { return [] }
    },
    fields: Array,
    userAgent: String,
    redirect: String
  },

  data() {
    return {
      checkout: window.location.pathname.startsWith("/checkout"),
      customFields: this.fields || [],
      showCustomFields: (this.fields && this.fields.some(field => !this.answers[field.id])),
      showTitle: true
    }
  },

  computed: {
    automaticallyRedirect() {
      return !this.showCustomFields && !this.showBenefits
    },

    iconSize() {
      return this.automaticallyRedirect ? "w-12 h-12" : "w-8 h-8"
    },

    showBenefits() {
      return this.showDiscord || this.showFeeds
    },

    showDiscord() {
      return this.discordURL !== undefined
    },

    showFeeds() {
      return this.feeds.length
    }
  },

  mounted() {
    if (Memberful.inIframe()) {
      Memberful.RpcClient.call("redirectOnOverlayCloseTo", [this.redirect])
    }

    if (this.automaticallyRedirect) {
      setTimeout(() => this.continue(), 3000)
    }

    this.previewChannel = new BroadcastChannel("website-form")
    this.previewChannel.onmessage = this.updateCustomFieldsPreview.bind(this)
  },

  beforeDestroy() {
    this.previewChannel.close()
  },

  methods: {
    continue() {
      if (Memberful.inIframe()) {
        Memberful.RpcClient.call("close")
      } else {
        this.$refs.link.click()
      }
    },

    hideFields() {
      if (this.showBenefits) {
        this.showCustomFields = false
        this.showTitle = false
      } else {
        this.continue()
      }
    },

    updateCustomFieldsPreview(message) {
      const { customFields } = message.data

      this.customFields = customFields
      this.showCustomFields = customFields.length !== 0
    }
  }
}
</script>

<template>
  <header>
    <hgroup class="checkout-header">
      <h1 class="mb-2 checkout-page-title">{{ t(".complete_order") }}</h1>
      <template v-if="planPrice == price">
        <h2 class="pb-2 mb-2 memberful-form-note">{{ planName }}: {{ formattedPlanPrice }}</h2>
      </template>
      <template v-else>
        <h2 class="mb-2">{{ planName }}: <span class="line-through">{{ formattedPlanPrice }}</span> {{ formattedPrice }}</h2>
      </template>
    </hgroup>
  </header>
</template>

<script>
import { formatCurrency } from "helpers/currency"

export default {
  props: [
    "planName",
    "planPrice",
    "price"
  ],

  computed: {
    formattedPlanPrice() {
      return formatCurrency(this.planPrice)
    },

    formattedPrice() {
      return formatCurrency(this.price)
    }
  }
}
</script>

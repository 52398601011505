import { Controller } from "@hotwired/stimulus"

const NOTIFICATIONS_ENABLED_KEY = "memberful.notifications_enabled"

export function notificationsEnabled() {
  return localStorage.getItem(NOTIFICATIONS_ENABLED_KEY)
}

export default class extends Controller {
  static targets = ["checkbox", "label", "disabledNotice"]

  connect() {
    this.notificationsEnabled = notificationsEnabled() && (Notification.permission === "granted")

    this.displayNotificationSwitch()
  }

  displayNotificationSwitch() {
    if (this.notificationsSupported) {
      if (notificationsEnabled()) {
        this.switchOn()
      } else {
        this.switchOff()
      }

      this.element.classList.remove("hidden")
    }
  }

  toggle(event) {
    event.preventDefault()

    if (Notification.permission === "granted") {
      if (notificationsEnabled()) {
        this.switchOff()
      } else {
        this.switchOn()
      }
    } else if (Notification.permission === "denied") {
      this.labelTarget.classList.add("!hidden")
      this.disabledNoticeTarget.classList.remove("hidden")
    } else {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          this.switchOn()
        }
      })
    }
  }

  switchOn() {
    this.checkboxTarget.checked = true
    this.notificationsEnabled = true
  }

  switchOff() {
    this.checkboxTarget.checked = false
    this.notificationsEnabled = false
  }

  get notificationsSupported() {
    return (("Notification" in window) && !(/Android.*Chrome/.test(navigator.userAgent)))
  }

  set notificationsEnabled(enable) {
    if (enable) {
      localStorage.setItem(NOTIFICATIONS_ENABLED_KEY, true)
    } else {
      localStorage.removeItem(NOTIFICATIONS_ENABLED_KEY)
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "country", "state"]

  connect() {
    this.updateStateField()
  }

  updateStateField() {
    if(this.selectedCountry.dataset.subdivisionLabel) {
      this.stateTarget.setAttribute("placeholder", this.selectedCountry.dataset.subdivisionLabel)
      this.stateTarget.classList.remove("hidden")
    } else {
      this.stateTarget.classList.add("hidden")
    }
  }

  get selectedCountry() {
    return this.countryTarget.options[this.countryTarget.selectedIndex]
  }
}

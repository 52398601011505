import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "disable"
  ]
  static values = {
    editPath: String
  }

  connect() {
    this.#dispatchLoadEvent()
    this.#disableTurboForms()

    this.channel = new BroadcastChannel("website-preview")
    this.channel.onmessage = this.receiveMessage.bind(this)
  }

  disconnect() {
    this.channel.close()
  }

  disableTargetConnected(element) {
    if (element.tagName == "A") {
      element.removeAttribute("href")
    } else {
      element.disabled = true
    }
  }

  receiveMessage(message) {
    const { action } = message.data

    if (action === "reload") {
      Turbo.visit(window.location)
    }
  }

  setRequestHeader(event) {
    event.preventDefault()
    event.detail.fetchOptions.headers["Memberful-Website"] = "preview"
    event.detail.resume()
  }

  #dispatchLoadEvent() {
    const detail = {
      title: document.title,
      path: document.location.pathname + document.location.search
    }

    if (this.hasEditPathValue) {
      detail.editPath = this.editPathValue
    }

    const event = new CustomEvent("website-preview:load", { detail })

    window.frameElement.dispatchEvent(event)
  }

  #disableTurboForms() {
    // Prevent Safari from allowing Turbo forms in iframe sandbox
    Turbo.setFormMode("off")
  }
}

import { depascalize } from "humps"
import I18n from "i18n"

const COMPONENT_NAME = /([a-z_]+)\.vue/i

export const i18nMixin = {
  methods: {
    t(key, options = {}) {
      if (key.startsWith(".")) {
        return relativeLookup(key.slice(1), options, this)
      } else {
        return I18n.t(key, options)
      }
    }
  }
}

const relativeLookup = function(key, options, vue) {
  const path = vue.$options.__file
  const component = path.match(COMPONENT_NAME)[1]
  const namespace = depascalize(component)

  return I18n.t(`components.${namespace}.${key}`, options)
}

<template>
  <div>
    <ErrorBox v-bind:errors="errors" />

    <div class="mb-3">
      <input
        autofocus
        type="text"
        class="input"
        v-model="recipientName"
        v-bind:class="{ with_error: hasError('recipient_name') }"
        :aria-label="t('.recipient_name')"
        :placeholder="t('.recipient_name')" />
    </div>

    <EmailInput
      name="recipient_email"
      v-model="recipientEmail"
      :placeholder="t('.recipient_email')"
      :unique="false"
      />

    <p class="label delivery-date">{{ t(".choose_delivery_date") }}</p>

    <DatePicker
       :initialDate="deliveryDate"
       v-on:dateChanged="setDeliveryDate"
       :hasError="hasError('delivery_date')" />
    <textarea
      v-model="message"
      class="mb-3 gift-message input"
      :aria-label="t('.personalize_message')"
      :placeholder="t('.personalize_message')"></textarea>

    <button v-on:click.prevent="goToPaymentInformation" class="btn-main btn-expanded flex items-center justify-center">
      <span>{{ t(".payment_information") }}</span>
      <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <title>{{ t(".chevron_right") }}</title>
        <path d="M15 9l-2.12 2.12L19.76 18l-6.88 6.88L15 27l9-9z"></path>
      </svg>
    </button>
  </div>
</template>

<script>
import DatePicker from "./date_picker.vue"
import EmailInput from "../components/EmailInput.vue"
import ErrorBox from "./error_box.vue"

import { EMAIL_REGEX } from "helpers/email"

const beginningOfDay = () => {
  let date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  return date
}
const initialDeliveryDate = beginningOfDay()

const objEmpty = obj => Object.keys(obj).every(key => obj[key] === undefined)

export default {
  components: {
    DatePicker,
    EmailInput,
    ErrorBox
  },

  data() {
    const params = (new URL(document.location)).searchParams

    return {
      deliveryDate: initialDeliveryDate,
      errors: {},
      message: "",
      recipientEmail: "",
      recipientName: params.get("recipient_name") || ""
    }
  },

  methods: {
    goToPaymentInformation() {
      this.errors = {}

      if(!this.recipientName.trim()) {
        this.errors.recipient_name = this.t(".name_required")
      }

      if(!EMAIL_REGEX.test(this.recipientEmail)) {
        this.errors.recipient_email = this.t(".invalid_email")
      }

      if(this.deliveryDate < initialDeliveryDate) {
        this.errors.delivery_date = this.t(".invalid_delivery_date")
      }

      if(objEmpty(this.errors)) {
        this.currentStep = "payment_info"
        this.$emit("update", {
          deliveryDate: this.deliveryDate,
          message: this.message,
          recipientEmail: this.recipientEmail.trim(),
          recipientName: this.recipientName.trim()
        })
      }
    },

    setDeliveryDate: function (date) {
      this.deliveryDate = date
    },

    hasError: function(element) {
      return element in this.errors
    }
  }
}
</script>

<template>
  <div class="@xl:mt-2">
    <div v-if="showPurchasablePrice" class="flex items-center justify-between mt-4 mb-2">
      <p class="flex-1">
        {{ item.name }}
      </p>
      <p class="opacity-80">
        {{ initialPrice }}
      </p>
    </div>

    <div v-if="baseCouponDiscount" class="flex items-center justify-between mb-2">
      <div class="flex-1 flex flex-row">
        <p>{{ t(".discount") }}</p>
      </div>
      <p class="opacity-80 discount-amount">
        -{{ formatCurrency(baseCouponDiscount.amount) }}
      </p>
    </div>

    <div class="flex items-center justify-between mb-2" v-if="displayTaxAmount">
      <p class="flex-1">{{ taxName }} ({{ taxRate }})</p>
      <p class="opacity-80">{{ formatCurrency(tax.amount) }}</p>
    </div>

    <div v-if="showDueDescription" class="flex items-center justify-between mb-2">
      <p class="flex-1 font-semibold">
        <span>{{ dueDate }}</span>
      </p>
      <p class="opacity-80 total-due">{{ formatCurrency(total) }}</p>
    </div>

    <div v-if="item.renewalDate" class="flex items-center justify-between mb-2">
      <p class="flex-1 font-semibold">
        <span>{{ t(".next_charge") }}</span>
      </p>
      <p class="opacity-80 total-due">{{ item.renewalDate }}</p>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "helpers/currency"
import { mapGetters, mapState } from "vuex"

import SvgIcon from "./icons/SvgIcon"

export default {
  components: {
    SvgIcon
  },

  props: {
    paymentDate: String,
  },

  computed: {
    dueDate() {
      if (this.paymentDate) {
        return this.t(".payment_date", { date: this.paymentDate })
      } else {
        return this.t(".due_today")
      }
    },

    initialPrice() {
      if (this.trial) {
        if (this.trial.paid) {
          return this.t(".paid_trial", {
            amount: formatCurrency(this.subtotal),
            length: this.trial.length,
            smart_count: this.trial.length }
          )
        } else {
          return this.t(".free_trial", { length: this.trial.length, smart_count: this.trial.length })
        }
      }

      return formatCurrency(this.subtotal)
    },

    showDueDescription() {
      return this.paymentDate || this.showPurchasablePrice
    },

    showPurchasablePrice() {
      return this.baseCouponDiscount || this.displayTaxAmount
    },

    taxName() {
      return this.t(`taxes.names.${this.tax.name}`)
    },

    ...mapGetters("checkout", [
      "couponDiscount",
      "displayTaxAmount",
      "subtotal",
      "taxRate",
      "total",
    ]),

    ...mapState("checkout", [
      "baseCouponDiscount",
      "item",
      "tax"
    ])
  },

  methods: {
    formatCurrency
  }
}
</script>

<template>
  <div>
    <p v-if="notice" class="flash notice" data-controller="auto-hide">{{ notice }}</p>

    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <SavedCard v-if="hasSavedCard" v-bind="savedCard">
      <template #header>
        <p class="mb-1 opacity-85">{{ t(".current_payment_method") }}</p>
      </template>
      <template #actions>
        <button type="button" @click="deleteCard" class="text-sm underline hover:no-underline focus:outline-none">
          <span class="sr-only">{{ t(".remove_payment_method") }}</span>
          <svg class="w-4 h-auto text-gray-500 fill-current hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><title>{{ t(".remove_payment_method") }}</title><g><rect x="5" y="7" width="2" height="6"></rect> <rect x="9" y="7" width="2" height="6"></rect> <path  d="M12,1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H0v2h1v10c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V5h1V3h-4V1z M6,2h4 v1H6V2z M13,5v9H3V5H13z"></path></g></svg>
        </button>
      </template>
    </SavedCard>

    <h3 v-if="hasSavedCard" class="opacity-85"> {{ t(".update_payment_method") }}</h3>

    <PaymentForm
      ref="paymentForm"
      method="PUT"
      :action="action"
      :buttonLabel="buttonLabel"
      :itemName="siteName"
      :requireBillingAddress="requireBillingAddress"
      @success="handleRedirect"
    />
  </div>
</template>

<script>
import PaymentForm from "./PaymentForm"
import SavedCard from "./SavedCard"

export default {
  components: {
    PaymentForm,
    SavedCard
  },

  props: {
    action: String,
    requireBillingAddress: Boolean,
    savedAddress: Object,
    savedCard: Object,
    savedTaxId: String,
    siteName: String,
  },

  data: function() {
    return {
      error: null,
      hasSavedCard: this.savedCard != null,
      notice: null
    }
  },

  computed: {
    buttonLabel() {
      return this.hasSavedCard ? this.t(".update_my_card") : this.t(".add_card")
    }
  },

  methods: {
    submit() {
      this.error = null
      this.$refs.paymentForm.submit()
    },

    deleteCard(event) {
      this.$http.delete(this.action).then(response => {
        this.hasSavedCard = null
        this.notice = this.t(".card_removed")
      }).catch(error => {
        this.error = this.t(".card_couldnt_be_removed")
      })
    },

    handleRedirect(response) {
      Turbo.cache.clear()
      Turbo.visit(response["redirect_to"], { action: "replace", frame: "member-account" })
    }
  }
}
</script>

import { getMetaValue } from "helpers"
import { formatCurrency } from "helpers/currency"
import I18n from "i18n"
import PaymentForm from "components/PaymentForm"
import VueForm from "../vue_form"

export default class extends VueForm(PaymentForm) {
  static targets = [ "cardError", "formError", "numberOfSeats", "freeNotice" ]
  static values = {
    seatPrice: Number,
    maximumNumberOfSeats: Number,
    minimumChargeAmount: Number
  }

  initialize() {
    this.errors = []
  }

  purchaseSeats(event) {
    event.preventDefault()

    if(this.validate()) {
      this.element.classList.remove("invalid")
      super.submit(event)
    } else {
      this.element.classList.add("invalid")
      this.showErrors()
    }
  }

  updatePrice() {
    if(this.numberOfSeats) {
      this.vm.$store.dispatch("checkout/updateLineItem", {
        name: "additional_seats",
        value: this.numberOfSeats,
        price: this.orderTotal
      })

      if(this.isFreeOrder) {
        this.vm.buttonLabel = this.t("add_new_seats")
        this.vm.requireCard = false
        this.freeNoticeTarget.classList.remove("hidden")
      } else {
        this.vm.buttonLabel = this.t("place_order")
        this.vm.requireCard = true
        this.freeNoticeTarget.classList.add("hidden")
      }
    } else {
      this.vm.buttonLabel = this.t("place_order")
    }
  }

  // private

  validate() {
    this.resetErrors()

    if(this.numberOfSeats < 1) {
      this.addError("number_of_seats", this.t("number_of_seats_too_low"))
    }

    if(this.maximumNumberOfSeatsValue && this.maximumNumberOfSeatsValue < this.numberOfSeats) {
      this.addError("number_of_seats", this.t("number_of_seats_too_high", { maximum: this.maximumNumberOfSeatsValue }))
    }

    let valid = this.element.checkValidity()

    return this.errors.length === 0 && valid
  }

  successCallback(response) {
    this.resetErrors()

    Turbo.cache.clear()
    Turbo.visit(response["redirect_to"], { action: "replace", frame: "member-account" })
  }

  errorCallback(message) {
    this.addError("base", message)
  }

  addError(field, errorMessage) {
    this.errors.push([field, errorMessage])
  }

  resetErrors() {
    this.errors = []
    this.formErrorTarget.innerHTML = ""

    document.querySelectorAll("input").forEach(input => {
      input.classList.remove("with_error")
    })
  }

  showErrors() {
    for (let [field, errorMessage] of this.errors.reverse()) {
      if (field !== "base") {
        $(`#${field}`).addClass("with_error")
      }

      const newError = document.createElement("p")
      newError.innerHTML = errorMessage
      this.formErrorTarget.insertBefore(newError, this.formErrorTarget.firstChild);
    }

    this.formErrorTarget.classList.toggle("hidden", this.errors.length == 0)
  }

  t(key, options = {}) {
    return I18n.t(`controllers.additional_seats.${key}`, options)
  }

  get numberOfSeats() {
    return parseInt(this.numberOfSeatsTarget.value) || 0
  }

  get orderTotal() {
    const total = this.seatPriceValue * this.numberOfSeats

    if(total < this.minimumChargeAmountValue) {
      return 0
    }

    return total
  }

  get isFreeOrder() {
    return this.orderTotal == 0
  }
}

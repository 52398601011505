<template>
  <div v-if="available">
    <button
      v-if="active"
      class="w-full mt-3 button"
      :aria-label="vendorLabel"
      :class="vendorClass"
      :type="express ? 'button' : 'submit'"
      @click="expressSubmit"
    >
    </button>

    <div v-if="express" class="my-4">
      <p class="text-center opacity-85">{{ t(".enter_payment_details") }}</p>
    </div>

    <div v-else class="mt-4 text-base text-center payment-method-toggle">
      <button type="button" class="text-gray-600 underline hover:no-underline hover:text-gray-700" @click="toggle">
        {{ this.active ? t(".enter_card") : t(".use_vendor", { vendor: vendorLabel }) }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"

function isAndroid() {
  return /Android/.test(navigator.userAgent)
}

export default {
  props: {
    default: Boolean,
    express: Boolean,
    item: String,
    requestPhone: Boolean,
    requestShipping: Boolean,
    showTotal: Boolean
  },

  data: function() {
    return {
      active: this.default && (this.express || !isAndroid()),
      available: false,
      country: window.memberful.config.country,
      currency: window.memberful.config.currency.code,
      vendor: null
    }
  },

  mounted() {
    this.paymentRequest = this.$stripe.paymentRequest(this.options)
    this.paymentRequest.on("paymentmethod", this.handlePaymentMethod)
    this.paymentRequest.on("cancel", () => this.$emit("cancel"))

    if (this.express) {
      this.paymentRequest.on("shippingaddresschange", this.updateTax)
    }

    this.paymentRequest.canMakePayment().then(this.ready)
  },

  computed: {
    displayItems() {
      if (this.showTotal && this.displayTaxAmount) {
        return [
          {
            amount: this.subtotal,
            label: this.item
          },
          {
            amount: this.tax.amount,
            label: this.t(`taxes.names.${this.tax.name}`)
          }
        ]
      }
    },

    options() {
      return {
        country: this.country,
        currency: this.currency,
        requestPayerEmail: this.express,
        requestPayerName: this.express,
        requestPayerPhone: this.requestPhone,
        requestShipping: this.requestShipping,
        total: {
          amount: this.showTotal ? this.total : 0,
          label: this.item
        }
      }
    },

    vendorClass() {
      if (this.vendor) return `${this.vendor.toLowerCase()}-pay-button`
    },

    vendorLabel() {
      return `${this.vendor} Pay`
    },

    ...mapGetters("checkout", [
      "displayTaxAmount",
      "subtotal",
      "total"
    ]),

    ...mapState("checkout", [
      "tax"
    ])
  },

  methods: {
    expressSubmit() {
      if (this.express) {
        this.$emit("show")
        this.submit()
      }
    },

    ready(wallets) {
      if (wallets) {
        if (wallets.applePay) {
          this.vendor = "Apple"
        } else if (wallets.googlePay) {
          this.vendor = "Google"
        }

        if (this.vendor) {
          this.available = true
          this.$emit("change", this.active)
        }
      }

      this.$emit("ready")
    },

    submit() {
      this.paymentRequest.update({
        shippingOptions: [],
        total: this.options.total
      })

      this.paymentRequest.show()
    },

    toggle() {
      this.active = !this.active
      this.$emit("change", this.active)
    },

    updateTax(event) {
      let { country, region: state, postalCode } = event.shippingAddress

      this.$store.dispatch("checkout/updateBillingAddress", { country, state, postalCode }).then(() => {
        event.updateWith({
          status: "success",
          total: this.options.total,
          displayItems: this.displayItems,
          shippingOptions: [
            {
              id: "none",
              label: this.t(".shipping_label"),
              amount: 0
            }
          ]
        })
      })
    },

    handlePaymentMethod(event) {
      this.$emit("payment-method", event)
    }
  }
}
</script>

<template>
  <div class="my-4">
    <div v-if="expandable" class="flex items-start justify-between">
      <p class="memberful-form-note">
        {{ t(".shipping_information") }}
      </p>

      <button
        v-if="!expanded"
        class="underline hover:no-underline text-sm text-gray-600"
        @click="manual"
      >
        {{ t(".enter_manually") }}
      </button>
    </div>

    <div>
      <div class="mb-3">
        <GooglePlaceAutocomplete
          ref="autocomplete"
          v-model="address.street"
          class="input"
          :apiKey="googlePlacesApiKey"
          :aria-label="labelFor('street_address')"
          :country="address.country"
          :placeholder="labelFor('street_address')"
          :required="required"
          @autocomplete="update"
          @autofill="handleAutofill"
          @invalid="emitError('street_address')"
        />
      </div>

      <div v-show="expanded">
        <div class="mb-3">
          <input
            v-model="address.line2"
            autocomplete="address-line2"
            class="input"
            :aria-label="labelFor('address_line2')"
            :placeholder="labelFor('address_line2')"
          />
        </div>

        <div class="flex flex-wrap items-center justify-between mb-0 lg:mb-3">
          <div class="w-2/3 pr-2 mb-3 lg:mb-0">
            <input
              v-model="address.city"
              autocomplete="address-level2"
              class="input"
              :aria-label="labelFor('city')"
              :placeholder="labelFor('city')"
              :required="required"
              @invalid="emitError('city')"
            />
          </div>

          <div class="w-1/3 pl-2 mb-3 lg:mb-0">
            <input
              v-model="address.postalCode"
              autocomplete="postal-code"
              class="input"
              :aria-label="labelFor('postal_code')"
              :placeholder="labelFor('postal_code')"
              :required="required"
              @invalid="emitError('postal_code')"
            />
          </div>
        </div>

        <div v-if="stateOptions" class="mb-3">
          <select
            v-model="currentState"
            class="select px-3"
            :aria-label="stateLabel"
            :required="required"
            @invalid="emitError('state')"
          >
            <option disabled value="">{{ stateLabel }}</option>
            <option v-for="(state, code) in stateOptions" :value="code">
              {{ state }}
            </option>
          </select>
        </div>

        <div v-else-if="stateLabel" class="mb-3">
          <input
            v-model="address.state"
            autocomplete="address-level1"
            class="input"
            :aria-label="stateLabel"
            :placeholder="stateLabel"
            @invalid="emitError('state')"
          />
        </div>
      </div>

      <div class="mb-3">
        <div class="relative">
          <label class="sr-only">
            {{ labelFor("country") }}
          </label>

          <select
            v-model="address.country"
            class="select px-3"
            :aria-label="labelFor('country')"
          >
            <option v-for="(country, code) in countries" :value="code">
            {{ country.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GooglePlaceAutocomplete from "./GooglePlaceAutocomplete"

export default {
  components: {
    GooglePlaceAutocomplete
  },

  model: {
    prop: "address"
  },

  props: {
    address: Object,
    expandable: Boolean,
    googlePlacesApiKey: String,
    required: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      expanded: !(this.expandable && this.googlePlacesApiKey)
    }
  },

  computed: {
    currentCountry() {
      return this.countries[this.address.country]
    },

    currentState: {
      get() {
        if (this.stateOptions && !this.stateOptions.hasOwnProperty(this.address.state)) {
          return ""
        } else {
          return this.address.state
        }
      },

      set(value) {
        this.$set(this.address, "state", value)
      }
    },

    stateLabel() {
      if (this.currentCountry.subdivisions) {
        const key = this.currentCountry.subdivisions.label_key
        if (key) return this.t(`subdivisions.labels.${key}`)
      }
    },

    stateOptions() {
      if (this.currentCountry.subdivisions) return this.currentCountry.subdivisions.options
    }
  },

  beforeCreate() {
    this.countries = window.memberful.config.countries
  },

  methods: {
    emitError(field) {
      this.expanded = true
      this.$emit("invalid", this.t("components.checkout_form.required", { attribute: this.labelFor(field) }))
    },

    handleAutofill(event) {
      if (event.target.matches(":-webkit-autofill") || event.target.matches(":autofill")) {
        this.expanded = true
      }
    },

    labelFor(component) {
      return this.t(`.${component}`)
    },

    manual() {
      this.$refs.autocomplete.disable()
      this.expanded = true
    },

    update(address) {
      this.expanded = true
      this.$emit("input", address)
    }
  }
}
</script>

import { Controller } from "@hotwired/stimulus"
import linkifyHtml from "linkify-html"

export default class extends Controller {
  connect() {
    this.element.innerHTML = linkifyHtml(this.element.innerHTML, {
      attributes: {
        title: "External link",
      },
      ignoreTags: ["script", "style"],
      rel: "nofollow",
      target: {
        url: "_blank",
      },
    })
  }
}

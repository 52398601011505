<template>
  <div>
    <div class="pb-2 text-sm text-red-500" v-if="showCouponError">
      {{ t(".invalid_coupon_code") }}
    </div>

    <div class="flex flex-wrap items-center justify-between space-x-2 relative my-3">
      <input
        v-model="couponCode"
        v-on:keydown.enter.prevent.stop="applyCouponCode"
        ref="couponCodeInput"
        type="text"
        class="input"
        :aria-label="t('.coupon_code')"
        :placeholder="t('.coupon_code')">

      <a href="#" v-on:click.prevent="applyCouponCode" class="absolute top-0 right-0 rounded-r bg-gray-50 border border-[#ddd] hover:bg-gray-100 h-[42px] inline-flex items-center justify-center px-4 cursor-pointer font-system text-sm font-medium focus:border-[#ccc]">{{ t(".apply") }}</a>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    "planId",
    "price"
  ],

  data() {
    return {
      couponCode: "",
      showCouponError: false
    }
  },

  methods: {
    applyCouponCode(event) {
      const coupon = {
        attribute: "coupon_code",
        value: this.couponCode,
      }

      this.$store.dispatch("checkout/updateCoupon", coupon).then(() => {
        this.showCouponError = false
      }).catch(error => {
        this.showCouponError = true
        this.couponCode = ""
      })
    }
  }
}
</script>

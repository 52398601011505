import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    text: String,
    title: String,
    url: String
  }

  connect() {
    if (!navigator.share) this.element.remove()
  }

  open(event) {
    navigator.share({
      text: this.textValue,
      title: this.titleValue,
      url: this.urlValue
    }).catch(() => {})
  }
}

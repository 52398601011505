<template>
  <div class="address-input" :class="{'collapsed': !showFields}">
    <fieldset v-show="showFields">
      <div class="relative mb-3">
        <span class="absolute flex items-center justify-center w-12 h-11 text-lg">
          {{ generateFlagEmoji(value.country) }}
        </span>

        <select
          id="country-select"
          :aria-label="t('.country')"
          :value="value.country"
          @change="updateCountry($event.target.value)"
          class="pl-10 select pr-8 text-ellipsis">

          <option v-for="(country, code) in countries" :value="code">
            {{ country.name }}
          </option>
        </select>
      </div>

      <div class="relative mb-3" v-if="stateOptions">
        <select
          required
          class="select pl-3 pr-8 text-ellipsis"
          :aria-label="stateLabel"
          :name="stateLabel.toLowerCase()"
          :value="value.state"
          @change="update('state', $event.target.value)"
          @invalid.prevent="showError(t('.select_subdivision', { subdivision: stateLabel.toLowerCase() }))">

          <option disabled :value="null">{{ stateLabel }}</option>
          <option v-for="(state, code) in stateOptions" :value="code">
            {{ state }}
          </option>
        </select>
      </div>

      <input
        v-if="showZipCode"
        class="input mb-3"
        inputmode="numeric"
        name="zip"
        required
        type="text"
        :aria-label="t('.zip')"
        :placeholder="t('.zip')"
        :value="value.postalCode"
        @change="updatePostalCode($event.target.value)"
        @invalid.prevent="showError(t('.enter_zip'))">

      <TaxIdInput
        ref="taxIdInput"
        :country="value.country"
      />
    </fieldset>

    <div v-if="!showFields" class="flex items-start my-3">
      <TaxLocation />

      <button
        type="button"
        class="border-0 m-0 underline cursor-pointer bg-transparent text-base hover:no-underline text-gray-600 mx-2"
        @click="expanded = true">
        ({{ t(".change") }})
      </button>

      <button
        v-if="taxIdLabel"
        type="button"
        class="border-0 m-0 underline cursor-pointer bg-transparent text-base hover:no-underline text-gray-600 ml-auto"
        @click="enterTaxId()">
        {{ t(".enter_tax_id", { tax_name: taxIdLabel }) }}
      </button>
    </div>
  </div>
</template>

<script>
import taxConfig from "taxes.json"
import TaxIdInput from "./TaxIdInput"
import TaxLocation from "./TaxLocation"
import { generateFlagEmoji } from "helpers"

const US_ZIP = /\d{5}/

export default {
  components: {
    TaxIdInput,
    TaxLocation
  },

  props: {
    cardPostalCode: String,
    useCardInput: Boolean
  },

  data: function() {
    return {
      expanded: false,
      inheritZipCode: true
    }
  },

  beforeCreate() {
    this.countries = window.memberful.config.countries
  },

  computed: {
    currentCountry() {
      return this.countries[this.value.country]
    },

    showFields() {
      return !this.useCardInput || this.expanded
    },

    showZipCode() {
      return !(this.useCardInput && this.inheritZipCode) && this.value.country == "US"
    },

    stateLabel() {
      if (this.currentCountry.subdivisions) {
        const key = this.currentCountry.subdivisions.label_key
        if (key) return this.t(`subdivisions.labels.${key}`)
      }
    },

    stateOptions() {
      if (this.currentCountry.subdivisions) return this.currentCountry.subdivisions.options
    },

    taxIdType() {
      if (taxConfig[this.value.country]) return taxConfig[this.value.country].tax_id_type
    },

    taxIdLabel() {
      if (this.taxIdType) return this.t(`taxes.tax_id_types.${this.taxIdType}`)
    },

    value: {
      get() {
        return this.$store.state.checkout.billingAddress
      },

      set(value) {
        this.$store.dispatch("checkout/updateBillingAddress", value)
      }
    }
  },

  watch: {
    cardPostalCode(value) {
      if (this.inheritZipCode) {
        this.inheritZipCode = US_ZIP.test(value)
      }

      if (!this.showZipCode) {
        this.updatePostalCode(value)
      }
    }
  },

  methods: {
    generateFlagEmoji,

    detectState(params) {
      if (this.stateOptions) {
        this.$http.post("/taxes/state", { json: params }).json().then(json => {
          this.update("state", json.state)
        })
      }
    },

    enterTaxId() {
      this.expanded = true
      this.$refs.taxIdInput.focus()
    },

    showError(message) {
      this.expanded = true
      this.$emit("error", message)
    },

    update(key, value) {
      this.value = Object.assign({}, this.value, { [key]: value })
    },

    updateCountry(value) {
      this.value = { country: value, state: null, postalCode: null }

      if (this.cardPostalCode) {
        this.$nextTick(() => this.updatePostalCode(this.cardPostalCode))
      }
    },

    updatePostalCode(value) {
      this.update("postalCode", value)
      this.detectState({ country: this.value.country, postal_code: value })
    }
  }
}
</script>

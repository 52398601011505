import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "helpers"
import I18n from "i18n"

const TURNSTILE_SCRIPT = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"

export default class extends Controller {
  detectChallenge({ target: form, detail }) {
    this.resetTurnstile()

    const { headers, status } = detail.fetchResponse.response
    const { submitter } = detail.formSubmission

    if (headers.get("cf-mitigated") === "challenge") {
      submitter.disabled = true
      submitter.dataset.enableWith ||= submitter.value
      submitter.value = I18n.t(`controllers.cloudflare.disable_with`)

      this.loadTurnstile(() => {
        this.renderTurnstile({ form, submitter })
      })
    } else if (status === 429) {
      const retryAfter = headers.get("Retry-After")

      this.#alertTooManyRequests(retryAfter)
    }
  }

  ignoreMissingFrame(event) {
    const { headers, status } = event.detail.response

    if (headers.get("cf-mitigated") === "challenge" || status === 429) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }

  loadTurnstile(onload) {
    if (window.turnstile) {
      onload()
    } else {
      const script = document.createElement("script")
      script.async = false
      script.onload = onload
      script.src = TURNSTILE_SCRIPT

      document.head.appendChild(script)
    }
  }

  renderTurnstile(context) {
    const { form, submitter } = context

    const widget = document.createElement("div")
    widget.className = "turnstile"

    context.widget = submitter.insertAdjacentElement("afterend", widget)

    const widgetId = turnstile.render(widget, {
      "action": "challenge",
      "callback": this.submitForm.bind(this, context),
      "error-callback": this.showError.bind(this, context),
      "response-field": this.testing,
      "retry": "never",
      "sitekey": this.sitekey,
      "theme": "light"
    })

    this.turnstile = { widget, widgetId }
  }

  resetTurnstile() {
    if (this.turnstile) {
      const { widget, widgetId } = this.turnstile

      turnstile.remove(widgetId)
      widget.remove()

      delete this.turnstile
    }
  }

  submitForm(context, token, preclearance) {
    const { form, submitter } = context

    if (preclearance || this.testing) {
      form.requestSubmit(submitter)
    } else {
      this.showError(context)
    }
  }

  showError(context) {
    const { submitter, widget } = context

    submitter.disabled = false
    submitter.value = submitter.dataset.enableWith

    const error = document.createElement("div")
    error.textContent = I18n.t(`controllers.cloudflare.error`)

    widget.appendChild(error)
  }

  #alertTooManyRequests(retryAfter) {
    const retryAfterSeconds = parseFloat(retryAfter)

    if (Number.isNaN(retryAfterSeconds)) {
      alert("You’ve reached the limit of actions allowed. Please wait a few minutes and try again.")
    } else {
      const retryAt = new Date()
      retryAt.setSeconds(retryAt.getSeconds() + retryAfterSeconds)

      alert(`You’ve reached the limit of actions allowed. Please try again after ${retryAt.toLocaleTimeString()}`)
    }
  }

  get sitekey() {
    return getMetaValue("turnstile-sitekey")
  }

  get testing() {
    return getMetaValue("turnstile-testing") === "true"
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { automatic: Boolean }

  connect() {
    if (Memberful.inIframe()) {
      Memberful.RpcClient.call("redirectOnOverlayCloseTo", [this.element.href])
    }

    if (this.automaticValue) {
      this.element.style.display = "none"
      setTimeout(() => this.continue(), 3000)
    }
  }

  continue() {
    if (Memberful.inIframe()) {
      Memberful.RpcClient.call("close")
    } else {
      this.element.click()
    }
  }
}

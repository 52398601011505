// Update URL search params based on DOM attribute mutations
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hidden"]

  connect() {
    this.observer = new MutationObserver(mutations => {
      for (let mutation of mutations) {
        if (mutation.attributeName === "hidden") {
          this.toggle(mutation.target, !mutation.target.hidden)
        }
      }
    })

    this.hiddenTargets.forEach(target => {
      this.observer.observe(target, { attributeFilter: ["hidden"] })
    })
  }

  disconnect() {
    this.observer.disconnect()
  }

  toggle(target, active) {
    const key = target.dataset.domSearchParamsKey
    const value = target.dataset.domSearchParamsValue

    let url = new URL(document.location)

    if (active) {
      url.searchParams.set(key, value)
    } else if (url.searchParams.get(key) === value) {
      url.searchParams.delete(key)
    }

    history.replaceState(history.state, "", url)
  }
}

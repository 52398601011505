<template>
  <p v-show="policies.length" v-html="policiesText" class="input-policy"></p>
</template>

<script>
import toSentence from "helpers/to_sentence"

export default {
  props: {
    sitePolicies: Array
  },

  data() {
    return {
      policies: this.sitePolicies
    }
  },

  computed: {
    policiesText() {
      let policyLinks = this.policies.map(policy => {
        return `<a href="${policy.url}" target="_blank">${policy.name}</a>`
      })

      return this.t(".policies_text", { policy_links: toSentence(policyLinks) })
    }
  }
}
</script>

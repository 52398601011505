import { Controller } from "@hotwired/stimulus"
import { createStore } from "store"
import Vue from "vue"

const VueComponent = (component) => class extends Controller {
  connect() {
    if (window.Turbo) { Turbo.cache.exemptPageFromPreview() }

    let el = document.createElement("div")
    this.replaceElement(el)

    const props = JSON.parse(this.element.dataset.props)

    const store = createStore(props.store)
    delete props.store

    this.vm = new Vue({
      el,
      store,
      render: h => h(component, { attrs: props })
    })
  }

  disconnect() {
    this.vm.$destroy()
  }

  replaceElement(el) {
    this.element.innerHTML = ""
    this.element.appendChild(el)
  }
}

export default VueComponent

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "animation" ]

  connect() {
    this.element.addEventListener("animationend", () => this.element.classList.remove(this.animationClass))
  }

  validate() {
    if (this.minValue && this.value < this.minValue) {
      this.value = this.minValue
      this.animate()
    } else if (this.maxValue && this.value > this.maxValue) {
      this.value = this.maxValue
      this.animate()
    }
  }

  // private

  animate() {
    this.element.classList.add(this.animationClass)
    this.element.focus()
  }

  get minValue() {
    return Number(this.element.min)
  }

  get maxValue() {
    return Number(this.element.max)
  }

  get value() {
    return Number(this.element.value) || 0
  }

  set value(newValue) {
    this.element.value = newValue
  }
}

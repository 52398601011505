import I18n from "i18n"

export default function toSentence(array) {
  const wordsConnector = I18n.t("word_connectors.words_connector")
  const twoWordsConnector = I18n.t("word_connectors.two_words_connector")
  const lastWordConnector = I18n.t("word_connectors.last_word_connector")

  switch (array.length) {
    case 0:
      return ""
    case 1:
      return `${array[0]}`
    case 2:
      return array.join(twoWordsConnector)
    default:
      const allButLastWord = array.slice(0, -1).join(wordsConnector)

      return `${allButLastWord}${lastWordConnector}${array.slice(-1)}`
  }
}

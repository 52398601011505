<template>
  <p class="line-clamp-2">
    {{ generateFlagEmoji(country) }} {{ location }}
  </p>
</template>

<script>
import { generateFlagEmoji } from "helpers"
import { mapState } from "vuex"

export default {
  beforeCreate() {
    this.countries = window.memberful.config.countries
  },

  computed: {
    country() {
      return this.billingAddress.country
    },

    currentCountry() {
      return this.countries[this.country]
    },

    location() {
      return [this.stateName, this.currentCountry.name].filter(Boolean).join(", ")
    },

    state() {
      return this.billingAddress.state
    },

    stateOptions() {
      if (this.currentCountry.subdivisions) return this.currentCountry.subdivisions.options
    },

    stateName() {
      if (this.stateOptions) return this.stateOptions[this.state]
    },

    ...mapState("checkout", {
      billingAddress: "billingAddress"
    })
  },

  methods: {
    generateFlagEmoji
  }
}
</script>

<template>
  <div class="flex items-center justify-start mb-3 gift-date-picker">
    <div class="relative w-2/5 mr-1">
      <select v-model="month" v-bind:class="{ with_error: hasError }" class="picker-month select" :aria-label="t('.month')">
        <option v-for="month in monthOptions" v-bind:value="month.value">
      {{ month.name }}
        </option>
      </select>
    </div>

    <div class="relative w-2/6 mx-1">
      <select v-model="day" v-bind:class="{ with_error: hasError }" class="picker-day select" :aria-label="t('.day')">
        <option v-for="day in dayOptions" v-bind:value="day">
        {{ day }}
        </option>
      </select>
    </div>

    <div class="relative w-2/6 ml-1">
      <select v-model="year" v-bind:class="{ with_error: hasError }" class="picker-year select" :aria-label="t('.year')">
        <option v-for="year in yearOptions" v-bind:value="year">
        {{ year }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  props: ["initialDate", "hasError"],

  data() {
    return {
      day: this.initialDate.getDate(),
      month: this.initialDate.getMonth(),
      monthOptions: Array(12).fill().map((_, i) => {
        return { value: i, name: this.t(`month_names.${i}`) }
      }),
      year: this.initialDate.getFullYear(),
      yearOptions: [this.initialDate.getFullYear(), this.initialDate.getFullYear() + 1, this.initialDate.getFullYear() + 2]
    }
  },

  computed: {
    dayOptions: function() {
      const nextMonth = this.month + 1
      const lastDayOfPreviousMonth = 0
      const daysInCurrentMonth = new Date(this.year, nextMonth, lastDayOfPreviousMonth).getDate()

      return Array(daysInCurrentMonth).fill().map((_, i) => i + 1)
    }
  },

  methods: {
    emitDateChange() {
      this.$emit("dateChanged", new Date(this.year, this.month, this.day))
    },

    fitDayInMonth() {
      if (!this.dayOptions.includes(this.day)) {
        const lastDay = this.dayOptions.slice(-1)[0]
        this.day = lastDay
      }
    }
  },

  watch: {
    "year": ["fitDayInMonth", "emitDateChange"],
    "day": "emitDateChange",
    "month": ["fitDayInMonth", "emitDateChange"],
  }
}
</script>

<template>
  <div class="mb-3 memberful-card-information">
    <div class="flex items-start justify-between memberful-payment-form__header">
      <p class="mb-2 opacity-85">{{ t(".payment_information") }}</p>
      <div class="flex items-center text-sm text-gray-600">
        <span class="mr-1">{{ t(".secure") }}</span>
        <svg class="w-4 h-4 -mt-px fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <title>{{ t(".secure") }}</title>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 9V7a5 5 0 0 1 10 0v2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2zm8-2v2H7V7a3 3 0 0 1 6 0z" />
        </svg>
      </div>
    </div>

    <stripe-card ref="card" @change="change" />

    <div v-if="error" class="mt-3 error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
import StripeCard from "./StripeCard"

export default {
  components: {
    StripeCard
  },

  props: {
    address: Object
  },

  data: function() {
    return {
      error: null
    }
  },

  computed: {
    paymentMethodData() {
      let data = {
        card: this.$refs.card.stripeElement,
        type: "card"
      }

      if (this.address) {
        let { country, state, postalCode: postal_code } = this.address
        data.billing_details = { address: { country, state, postal_code } }
      }

      return data
    }
  },

  methods: {
    change(event) {
      if (event.error) {
        this.error = event.error.message
      } else {
        this.error = null
      }
      if (event.complete) {
        this.$emit("complete", event.value)
      }
    },

    submit() {
      this.createPaymentMethod()
    },

    createPaymentMethod() {
      this.$stripe.createPaymentMethod(this.paymentMethodData).then(result => {
        if (result.error) {
          this.error = result.error.message
          this.$emit("error", result.error.message)
        } else {
          this.error = null
          this.$emit("payment-method", result)
        }
      })
    },

    focus() {
      this.$refs.card.focus()
    }
  }
}
</script>

import { Controller } from "@hotwired/stimulus"
import { getMetaValue } from "helpers"

export default class extends Controller {
  static targets = [ "errorMessage", "paymentIntentClientSecret", "paymentIntentId", "submitButton" ]

  connect() {
    if (this.hasPaymentIntentClientSecretTarget) {
      this.disableSubmitButton()
      this.handleCardAction(this.paymentIntentClientSecretTarget.value)
    }
  }

  handleCardAction(clientSecret) {
    const stripe = Stripe(getMetaValue("stripe-key"), {
      stripeAccount: getMetaValue("stripe-account")
    })

    stripe.handleCardAction(clientSecret).then(result => {
      if (result.error) {
        this.showErrorMessage(result.error.message)
        this.enableSubmitButtton()
      } else {
        this.paymentIntentIdTarget.value = result.paymentIntent.id
        this.submitForm()
      }
    })
  }

  disableSubmitButton() {
    $.rails.disableElement($(this.submitButtonTarget))
  }

  enableSubmitButtton() {
    $.rails.enableElement($(this.submitButtonTarget))
  }

  showErrorMessage(message) {
    this.errorMessageTarget.classList.remove("hidden")
    this.errorMessageTarget.innerHTML = `<p>${message}</p>`
  }

  submitForm() {
    $(this.element).trigger("submit.rails")
  }
}

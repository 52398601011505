<template>
  <div class="mb-3">
    <input
      autofocus
      autocomplete="tel"
      class="input"
      pattern="^\+?((\( ?)?[0-9]+( ?\))?[\-\. ]?)+$"
      type="tel"
      :aria-label="label"
      :placeholder="label"
      :required="required"
      :value="value"
      @input="input"
      @invalid="$emit('invalid', t('.error'))"
    />
  </div>
</template>

<script>
export default {
  props: {
    required: {
      type: Boolean,
      default: true
    },
    value: String
  },

  computed: {
    label() {
      return this.t(".label")
    }
  },

  methods: {
    input(event) {
      let digits = Array.from(event.target.value.matchAll(/[0-9]/g), m => m[0]).length

      if (this.required && (digits < 8 || digits > 15)) {
        event.target.setCustomValidity("invalid")
      } else {
        event.target.setCustomValidity("")
      }

      this.$emit("input", event.target.value)
    }
  }
}
</script>

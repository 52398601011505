export function trackOrderInGoogleAnalytics({ orderNumber, orderTotal, planName }) {
  if (typeof gtag === "function") {
    gtag("event", "purchase", {
      "transaction_id": orderNumber,
      "value": orderTotal,
      "currency": window.memberful.config.currency.code,
      "items": [
        {
          "name": planName,
          "quantity": 1,
          "price": orderTotal
        }
      ]
    });
  }
}

<template>
  <form novalidate :class="{ invalid: error }" @submit.prevent="submit">
    <p v-if="error" class="error-message">{{ error }}</p>

    <CustomFieldInput
      v-for="field in fields"
      :key="field.id"
      v-bind="field"
      v-model="values[field.id]"
    />

    <button class="btn-main btn-expanded" type="submit">{{ t(".done") }}</button>
  </form>
</template>

<script>
import CustomFieldInput from "./CustomFieldInput"

export default {
  components: {
    CustomFieldInput
  },

  props: {
    fields: Array,
    answers: Object
  },

  data() {
    return {
      error: null,
      values: {
        ...this.answers
      }
    }
  },

  methods: {
    submit(event) {
      let params = {
        member: {
          custom_fields: this.values
        }
      }

      if (event.target.checkValidity()) {
        this.error = null

        this.$http.patch("/account/preferences", { json: params }).then(response => {
          if (this.$listeners.complete) {
            this.$emit("complete")
          } else {
            Turbo.cache.clear()
            Turbo.visit("/account", { action: "replace", frame: "member-account" })
          }
        }).catch(error => {
          this.error = this.t(".error")
        })
      } else {
        this.error = this.t(".incomplete")
      }
    }
  }
}
</script>

export default function getTrackingParameters(url) {
  const customParamPrefix = "mt_"
  const defaultTrackingParams = [
    "referrer",
    "utm_campaign",
    "utm_content",
    "utm_medium",
    "utm_source",
    "utm_term"
  ]
  let params = {}
  url = new URL(url)

  url.searchParams.forEach((value, key) => {
    if (defaultTrackingParams.includes(key)) {
      params[key] = value
    }

    if (key.startsWith(customParamPrefix)) {
      const unprefixedKey = key.replace(customParamPrefix, "")
      params[unprefixedKey] = value
    }
  })

  return params
}

<template>
  <div>
    <label for="price" class="opacity-85">{{ label }}</label>

    <div v-if="error" class="error-message">{{ error }}</div>

    <div class="relative mb-4">
      <div class="inline-flex w-12 items-center justify-center border-t border-b border-l rounded-l border-r text-base flex-shrink-0 select-none pointer-events-none bg-white absolute left-0 top-0 leading-none pt-[0.75rem] pb-[0.75rem] border-gray-400 touch-none">
        {{ currencySymbol }}
      </div>
      <input
        ref="input"
        v-model.lazy.number="decimalValue"
        v-bind="disablePasswordManagers"
        class="pl-14 input"
        id="price"
        inputmode="numeric"
        @change="save"
        @invalid.prevent
        @keydown.enter.prevent
      />

      <div class="flex absolute top-0 right-0">
        <button
          class="inline-flex w-12 items-center justify-center border-t border-b focus:border-gray-500 hover:bg-gray-200 duration-300 transition text-lg flex-shrink-0 hover:shadow-sm bg-white pt-[0.75rem] pb-[0.75rem] border-gray-400 touch-none border-l"
          type="button"
          :aria-label="t('.decrease_amount')"
          :title="t('.decrease_amount')"
          @click="update(value - SUBUNITS)"
        >
          <SvgIcon name="minus" class="fill-current w-4 h-auto flex-shrink-0" />
        </button>

        <button
          class="inline-flex w-12 items-center justify-center border-t border-b focus:border-gray-500 hover:bg-gray-200 duration-300 transition text-lg flex-shrink-0 hover:shadow-sm bg-white pt-[0.75rem] pb-[0.75rem] border-gray-400 touch-none border-l border-r rounded-r"
          type="button"
          :aria-label="t('.increase_amount')"
          :title="t('.increase_amount')"
          @click="update(value + SUBUNITS)"
        >
          <SvgIcon name="plus" class="fill-current w-4 h-auto flex-shrink-0" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const STRIPE_MAXIMUM = 99999999
const SUBUNITS = 10 ** memberful.config.currency.decimal_places

import SvgIcon from "./icons/SvgIcon"

import { debounce } from "lodash"
import { mapGetters, mapState } from "vuex"

export default {
  components: {
    SvgIcon
  },

  props: {
    label: String
  },

  data() {
    return {
      error: null,
      value: null
    }
  },

  computed: {
    currencySymbol: function () {
      return window.memberful.config.currency.symbol
    },

    decimalValue: {
      get() {
        return isNaN(this.value) ? this.value : this.formatValue(this.value)
      },

      set(number) {
        this.value = isNaN(number) ? number : Math.round(number * SUBUNITS)
      }
    },

    disablePasswordManagers: function() {
      return {
        autocomplete: "off",
        "data-form-type": "other", // Dashlane
        "data-lpignore": "true" // LastPass
      }
    },

    ...mapGetters("checkout", ['minimumPrice'])
  },

  watch: {
    minimumPrice: function (newPrice, oldPrice) {
      if (this.value == oldPrice || this.value < newPrice) {
        this.value = newPrice
      }

      this.save()
    }
  },

  created() {
    this.debouncedSave = debounce(this.save, 500)
    this.SUBUNITS = SUBUNITS
    this.value = this.$store.state.checkout.memberPrice || this.minimumPrice
  },

  methods: {
    formatValue(value) {
      return (value / SUBUNITS).toFixed(memberful.config.currency.decimal_places)
    },

    save() {
      if (this.validate()) {
        this.$emit("change", this.decimalValue)
        this.$store.dispatch("checkout/updateMemberPrice", this.value)
      }
    },

    update(value) {
      if (value > this.minimumPrice && value < STRIPE_MAXIMUM) {
        this.value = value
      } else {
        this.value = this.minimumPrice
      }

      this.debouncedSave()
    },

    validate() {
      this.error = null

      if (isNaN(this.value) || this.value < this.minimumPrice) {
        this.error = this.t(".error.minimum", { minimum: this.formatValue(this.minimumPrice) })
      } else if (this.value > STRIPE_MAXIMUM) {
        this.error = this.t(".error.maximum", { maximum: this.formatValue(STRIPE_MAXIMUM) })
      }

      this.$refs.input.setCustomValidity(this.error || "")

      return this.error === null
    }
  }
}
</script>

<template>
  <input
    ref="input"
    autofocus
    type="text"
    :value="value"
    @input="handleInput"
    @invalid="$emit('invalid', $event)"
    @keydown.enter.prevent
  />
</template>

<script>
import { Loader as GoogleMapsLoader } from "@googlemaps/js-api-loader"

export default {
  props: {
    apiKey: String,
    country: {
      required: true,
      type: String
    },
    value: String
  },

  watch: {
    country: function(value) {
      this.autocomplete.setComponentRestrictions({ country: value })
    }
  },

  mounted() {
    if (this.apiKey) {
      const loader = new GoogleMapsLoader({
        apiKey: this.apiKey,
        version: "weekly",
        libraries: ["places"]
      })

      loader.load().then(this.setup)
    }
  },

  methods: {
    disable() {
      if (this.autocomplete) {
        google.maps.event.clearInstanceListeners(this.$refs.input)
        this.$refs.input.type = "text"
        this.$refs.input.autocomplete = "address-line1"
      }
    },

    handleInput(event) {
      this.$emit("input", event.target.value)
      this.$emit("autofill", event)
      return event.target.value
    },

    setup() {
      this.$refs.input.type = "search"

      this.autocomplete = new google.maps.places.Autocomplete(this.$refs.input, {
        componentRestrictions: {
          country: this.country
        },
        fields: [
          "address_components"
        ]
      })

      this.autocomplete.addListener("place_changed", this.update)
    },

    update() {
      let place = this.autocomplete.getPlace()

      let address = {}
      let number

      for (const component of place.address_components) {
        switch (component.types[0]) {
          case "street_number":
            number = component.long_name
            break
          case "route":
            address.street = component.short_name
            break
          case "subpremise":
            address.line2 = component.long_name
            break
          case "postal_code":
            address.postalCode = component.long_name
            break
          case "locality":
          case "postal_town":
            address.city = component.long_name
            break
          case "administrative_area_level_1":
            address.state = component.short_name
            break
          case "country":
            address.country = component.short_name
            break
        }
      }

      if (number) {
        address.street = this.t("address.format", {
          street_number: number,
          street_name: address.street
        })
      }

      this.$emit("autocomplete", address)
      this.$nextTick(() => this.$forceUpdate())
    }
  }
}
</script>

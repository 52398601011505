import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    useTransition(this, {
      element: this.containerTarget,
    })

    // Set initial ARIA attributes
    this.containerTarget.setAttribute("aria-haspopup", "true")
    this.containerTarget.setAttribute("aria-expanded", "false")
  }

  toggle() {
    if (this.containerTarget.classList.contains("hidden")) {
      this.enter()
      this.containerTarget.setAttribute("aria-expanded", "true")
    } else {
      this.leave()
      this.containerTarget.setAttribute("aria-expanded", "false")
    }
  }

  hide(event) {
    if (this.outsideClick(event)) {
      this.leave()
      this.containerTarget.setAttribute("aria-expanded", "false")
    }
  }

  // private

  outsideClick(event) {
    return !this.element.contains(event.target)
  }
}

<template>
  <div data-controller="accordion dom-search-params" :data-accordion-expanded-value="expanded">
    <div v-for="feed in feeds" class="mb-4 border border-gray-300 rounded">
      <h2>
        <button :id="`summary_feed_${feed.id}`" :aria-controls="`details_feed_${feed.id}`" data-accordion-target="summary" data-action="click->accordion#toggle" type="button" class="member-feed__button flex justify-between items-center w-full p-4">
          <span class="font-bold text-gray-800 text-left pr-6 leading-tight">{{ feed.name }}</span>

          <span class="member-feed__subscribe btn btn-default btn-sm">
            {{ t(".subscribe") }}
          </span>

          <span class="member-feed__close btn btn-default btn-sm">
            {{ t(".close") }}
          </span>
        </button>
      </h2>

      <div :id="`details_feed_${feed.id}`" role="region" :aria-labelledby="`summary_feed_${feed.id}`" data-accordion-target="details" data-dom-search-params-target="hidden" data-dom-search-params-key="id" :data-dom-search-params-value="feed.id">
        <PodcastAppSelect
          :userAgent="userAgent"
          :feed="feed"
          v-model="app"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PodcastAppSelect from "./PodcastAppSelect"

export default {
  components: {
    PodcastAppSelect
  },

  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    feeds: Array,
    userAgent: String
  },

  data() {
    return {
      app: null
    }
  },

  computed: {
    expanded() {
      if (this.collapsed) return
      if (this.feeds.length === 1) {
        return `summary_feed_${this.feeds[0].id}`
      } else {
        const searchParams = new URLSearchParams(document.location.search)
        const id = parseInt(searchParams.get("id"))

        if (id) return `summary_feed_${id}`
      }
    }
  }
}
</script>

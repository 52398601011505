import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.channel = new BroadcastChannel("website-form")
    this.channel.onmessage = this.receiveMessage.bind(this)
  }

  disconnect() {
    this.channel.close()
  }

  receiveMessage(message) {
    const { input } = message.data

    if (input.type === "checkbox") {
      this.element.querySelectorAll(`[data-website-form-checkbox-id=${input.id}]`).forEach(target => {
        target.classList.toggle("hidden", !input.checked)
      })
    } else if (input.type === "text") {
      this.element.querySelectorAll(`[data-website-form-text-id=${input.id}]`).forEach(target => {
        target.textContent = input.value
      })
    }
  }
}
